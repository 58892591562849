import React, { Component } from 'react';
import { cmsGetServiceSyncJobs, cmsGetServiceSyncJobsPromise } from '../../CallMSAPI.js';
import { SelectColumnFilter } from '../../ExpandingReactTable';
import SyncJobOutput from './SyncJobOutput';
import ExpandingTable from '../../ExpandingReactTable';
import { toast } from 'react-toastify';
import { serviceCodeToIcon } from '../../CallMSUIHelpers.js'
import { serviceNameExpander } from '../ServiceFormHelpers.js';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import dayjs from '../../dayjs.js';

var _ = require('lodash');


class SyncJobTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // Used by table
            filters:[],
            searchText:[]
        }

        this.getColumns = this.getColumns.bind(this);
        this.fetchData = this.fetchData.bind(this);

        this.tableRef = React.createRef();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.lastUpdate != this.props.lastUpdate) {
            return true;
        } else {
            return false;
        }
    }

    fetchData(filterValues, searchText, pageSize, page, sortSettings) {
        let self = this;

        var apiParams = {
            'SortBy': 'CreatedOn',
            'SortDirection': 'Descending',
            'IncludeInactive': true
        };
        if (pageSize) {
            apiParams['pageSize'] = pageSize;
        }

        if (page) {
            apiParams['currentPage'] = page;
        }

        if(sortSettings) {
            apiParams['SortBy'] = sortSettings.Column;
            apiParams['SortDirection'] = sortSettings.SortDirection;
        }

        if(filterValues){
            if(filterValues['service']) {
                apiParams['accountServiceId'] = filterValues['service'];
            }
            if(filterValues['state']) {
                apiParams['State'] = filterValues['state'];
            }
            if(filterValues['type']) {
                apiParams['Type'] = filterValues['type'];
            }
        }
        
        return  cmsGetServiceSyncJobsPromise(
            self.props.account.Id,
            apiParams
        );
    }

    getColumns() {
        var self = this;
        var columns = [];

        columns.push(
            {
                id: "state_change",
                Header: "Date",
                accessor: function (d) {
                    let t = dayjs.utc(d.StateChangedOn);
                    if(t.isValid()) {
                        if (t.year() === dayjs().year()) {
                            return <span title={t.fromNow()}>{t.local().format('MMM D, H:mm:ss')}</span>;
                        } else {
                            return <span title={t.fromNow()}>{t.local().format('MMM D YYYY, H:mm:ss')}</span>;
                        }
                    } else {
                        return d.StateChangedOn;
                    }
                },
                filterable: false
            }
        );

        columns.push(
            {
                id: "service",
                Header: "Service",
                accessor: function (d) {
                    var service = _.find(self.props.services, { Id: d.AccountServiceId });

                    var debugIcon = null;
                    if (d.CaptureDebug) {
                        debugIcon = <i className="fa-solid fa-bug"></i>
                    }

                    var exceptionIcon = null;
                    if (d.HasException && self.props.hasRole('ViewExceptions')) {
                        exceptionIcon = <i className="fa-solid fa-triangle-exclamation"></i>
                    }

                    if (service) {
                        var icon = serviceCodeToIcon(service.ServiceCode);
                        var displayName = serviceNameExpander(service);
                        return <span title={d.Id}>{icon} {displayName} {debugIcon} {exceptionIcon}</span>;
                    } else {
                        return <em title={d.Id}>Unknown service {d.AccountServiceId} {debugIcon} {exceptionIcon}</em>;
                    }
                },
                filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                        return true;
                    }
                    if (filter.value === "true") {
                        return true;
                    }
                    return false;
                },
                Filter: ({ filter, onChange }) => self.serviceFilter(filter, onChange, 'service')
            }
        );

        const nextGenSyncEnabled = this.props?.account?.IsBetaFeaturesEnabled != null && this.props.account.IsBetaFeaturesEnabled.includes("ENABLENEXTGENSYNC");
        if (nextGenSyncEnabled) {
            columns.push(
                {
                    id: "type",
                    Header: "Type",
                    accessor: function (d) {
                        const isNextGen = d.Id != null && d.Id.indexOf('-') !== -1;
                        if (d.IsAutoSync)
                            return <><i class="fa-regular fa-gear"></i> Auto</>;
                        else if (isNextGen)
                            return <><i class="fa-regular fa-hand-pointer"></i> Manual</>;
                        else
                            return <><i class="fa-regular fa-circle-exclamation"></i> Manual</>;
                    },
                    Filter: ({ filter, onChange }) => self.serviceFilter(filter, onChange, 'type')
                }
            );
        }

        columns.push(
            {
                id: "state",
                Header: "State",
                accessor: function (d) {
                    if (d.State === 'Completed') {
                        return <i className="fa-solid fa-check"></i>;
                    } else {
                        return d.State;
                    }
                },
                filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                        return true;
                    }
                    if (filter.value === "true") {
                        return true;
                    }
                    return false;
                },
                Filter: ({ filter, onChange }) => self.serviceFilter(filter, onChange, 'state')
            }
        );

        return columns;
    }

    serviceFilter(filter, onChange, type) {
        var self = this;
        var opts = null;
        switch (type) {
            case 'state':
                opts = [
                    <option key={'Failed'} value={'Failed'}>Failed</option>,
                    <option key={'Completed'} value={'Completed'}>Completed</option>,
                    <option key={'Running'} value={'Running'}>Running</option>,
                    <option key={'Queued'} value={'Queued'}>Queued</option>,
                    <option key={'Cancelled'} value={'Cancelled'}>Cancelled</option>,
                    <option key={'ManualIntervention'} value={'ManualIntervention'}>ManualIntervention</option>
                ];
                break;
            case 'service':
                opts = _.map(
                    _.sortBy(
                        _.filter(
                            self.props.services, function (s) { s.DisplayName = serviceNameExpander(s); return s.SyncEnabled }
                        ),
                        'DisplayName'
                    ),
                    function (s) {
                        return (<option key={s.Id} value={s.Id}> {s.DisplayName}</ option>);
                    }
                );
                break;
            case 'type':
                opts = [
                    <option key={'Manual'} value={'Manual'}>Manual</option>,
                    <option key={'Auto'} value={'Auto'}>Auto</option>,
                ];
                break;
        }

        return (
            <select
                onChange={event => {
                    let temp = [...this.state.filters];
                    temp[type] = event.target.value;
                    self.setState({filters: temp})
                }}
                style={{ width: "100%" }}
                value={self.state.filters && self.state.filters[type] ? self.state.filters[type] : "all"}
            >
                <option key='default' value=''>All</option>
                {opts}
            </select>
        );
    }

    render() {
        var self = this;

        var cols = self.getColumns();

        return (
            <ExpandingTable
                fetchData={this.fetchData}
                filters={this.state.filters}
                searchText={this.state.searchText}
                pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                sortBy={{
                    'Column': 'CreatedOn',
                    'SortDirection': 'Descending'
                }}
                update={this.props.lastUpdate}
                columns={cols}
                loading={this.state.loading}
                loadingText={"Loading..."}
                noDataText={"There are no sync jobs to show."}
                showPagination={true}
                className="-striped -highlight"
                // Tell react-table that we'll be sorting pagination and sorting via server side
                manual
                // Important to allow manual updating
                ref={this.tableRef}
                SubComponent={row => {
                    return <SyncJobOutput jobId={row.original.Id} />;
                }}
            />
        );
    }
}
export default connect(state => ({
    account: state.account.account
}), dispatch => ({
    hasRole: (uiPart = '') => dispatch(actions.hasRole(uiPart))
}))(SyncJobTable);
