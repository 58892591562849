import React, { useState, useEffect } from "react";
import AutoSyncRow from "./AutoSyncRow";
import {
  TeachingBubble,
  Toggle,
  DefaultButton,
  Dialog,
  DialogType,
  PrimaryButton,
} from "@fluentui/react";
import { toast } from "react-toastify";
import { cmsDisableAutoSync } from "../../CallMSAPI.js";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index.js";
import { cmsEditAccountPromise, cmsStartOAuthServiceSync } from "../../CallMSAPI.js";

var _ = require("lodash");

const NextGenPhoneAppRow = (props) => {
  const [isNextGen, setIsNextGen] = useState(
    props.account.IsBetaFeaturesEnabled
      ? props.account.IsBetaFeaturesEnabled.includes("ENABLENEXTGENPHONEAPP")
      : false
  );
  const [showNextGenPhoneAppHelp, setShowNextGenPhoneAppHelp] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const toggleNextGenPhoneAppHelp = () => {
    setShowNextGenPhoneAppHelp(!showNextGenPhoneAppHelp);
  };

  const dismissNextGenPhoneAppHelp = () => {
    setShowNextGenPhoneAppHelp(false);
  };

  const dismissConfirmDialog = (newVal) => {
    setShowConfirmModal(false);
  };
  const showConfirmDialog = () => {
    setShowConfirmModal(true);
  };

  const modalProps = {
    isBlocking: true,
    className: "allow-overflow-modal",
  };

  return (
    <>
      {isNextGen ? (
        <div className="row">
          <div className="col-md-4">
            <Toggle
              label={
                <div>
                  <label htmlFor="nextGenPhoneAppBtn">Next Gen Phone App</label>
                  <button
                    id="nextGenPhoneAppBtn"
                    className="btn btn-link btn--faux-link"
                    onClick={(e) => {
                      e.preventDefault();
                      toggleNextGenPhoneAppHelp();
                    }}
                  >
                    <i
                      className="fa-solid fa-question-circle"
                      id={"nextGenPhoneAppHelp"}
                    />
                  </button>
                  {showNextGenPhoneAppHelp && (
                    <TeachingBubble
                      target={"#nextGenPhoneAppHelp"}
                      hasCondensedHeadline={true}
                      onDismiss={dismissNextGenPhoneAppHelp}
                      hasCloseIcon={true}
                      closeButtonAriaLabel="Close"
                    >
                      <>
                        <p>
                          This new Click to dial experience of phone app ensure
                          a smoother UX and improved response time for outbound
                          calls to initiate.
                        </p>
                        <p>
                          Please Note: You will need to have an additional
                          Resource account license available inside your
                          Microsoft Tenant.
                        </p>
                      </>
                    </TeachingBubble>
                  )}
                </div>
              }
              defaultChecked={props.values.IsSingleStage}
              checked={props.values.IsSingleStage}
              onText="On"
              offText="Off"
              onChange={(_e, checked) => {
                props.setFieldValue("IsSingleStage", checked);
                if (checked) {
                  return showConfirmDialog();
                }
              }}
              disabled={props.disabled}
            />
          </div>
          <Dialog
            hidden={!showConfirmModal}
            onDismiss={() => dismissConfirmDialog(false)}
            modalProps={modalProps}
            style={{ minWidth: "80%", overflow: "visible" }}
            dialogContentProps={{
              type: DialogType.normal,
              title: "Manual Sync Required",
              className: "manualSync",
              subText:
                "To complete activation a one-time manual sync needs to be run with your Microsoft Administrator Credentials.",
            }}
          >
            <div>
              <PrimaryButton
                onClick={() => {
                  dismissConfirmDialog();
                  cmsStartOAuthServiceSync(
                    props.account.Id,
                    props.fullTeams[0]
                  );
                }}
              >
                <i
                  className="fa fa-warning"
                  style={{ marginRight: "10px" }}
                ></i>
                Sync Now - Changes Queued
              </PrimaryButton>
            </div>
          </Dialog>
        </div>
      ) : null}
    </>
  );
};
const mapStateToProps = (state) => {
  const account = state.account;
  const services = state.services;
  return {
    account: account.account,
    fullTeams: services.fullTeams,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    refetchServices: () => dispatch(actions.refetchServices()),
    refreshAccount: () => dispatch(actions.refreshAccount()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NextGenPhoneAppRow);
