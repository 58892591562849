import React, { Component } from 'react';
import { showConsoleLogs } from '../CallMSUIHelpers.js';
import { getErrMsg, cmsGet, cmsDeleteAdminUserPromise, cmsGetPromise } from '../CallMSAPI.js';

import ExpandingTable from '../ExpandingReactTable';
import EditAdminUserForm from "./EditAdminUserForm";
import { toast } from 'react-toastify';
import { connect } from 'react-redux';

class AdminUserReactTable extends Component { 
    constructor(props) {
        super(props);

        this.state = {
            filters: [],
            searchText: []
        }
        
        this.fetchData = this.fetchData.bind(this);
        this.tableFilter = this.tableFilter.bind(this);
        this.deleteAdminUser = this.deleteAdminUser.bind(this);
    }

    // Manually fire a react-table update when our upstream state increments
    componentDidUpdate(prevProps, prevState, snapshot) {
        var self = this;
        if (prevProps.lastUpdate !== this.props.lastUpdate) {
            if(showConsoleLogs()){
                console.log("Starting an update");
            }
            self.forceTableUpdate();
        }
    }

    forceTableUpdate() {
        let self = this;
        self.setState({ count: self.props.lastUpdate})
    }

    tableFilter(filter, onChange, type) {
        var self = this;
        var opts = null;

        return (
            <input
                onChange={event => {
                    let temp =[];
                    temp[type] = event.currentTarget.value;
                    self.setState({searchText:  temp});
                }}
                style={{ width: "100%" }}
                value={self.state.searchText[type]}
            />
        );
    }

    fetchData(filterValues, searchText, pageSize, page, sortSettings) {
        let self = this;
        var apiParams = {
            accountId: self.props.account.Id,
            objectType: 'adminusers',
            SortBy: 'name',
            SortDirection: 'Descending'
        };

        if (pageSize) {
            apiParams['pageSize'] = pageSize;
        }

        if (page) {
            apiParams['currentPage'] = page;
        }

        if(sortSettings) {
            apiParams['SortBy'] = sortSettings.Column;
            if(sortSettings.Column === 'name'){
                apiParams['SortBy'] = 'FirstName';
            }
            if(sortSettings.Column === 'Email'){
                apiParams['SortBy'] = 'UPN';
            }
            apiParams['SortDirection'] = sortSettings.SortDirection;
        }

        if(searchText){
            if(searchText['name']) {
                apiParams['searchText'] = searchText['name'];
            }
            if(searchText['email']) {
                apiParams['searchText'] = searchText['email'];
            }
        }
        return cmsGetPromise(apiParams);
    }

    deleteAdminUser(event, data) {
        var self = this;
        event.preventDefault();
        if (window.confirm("Are you sure you want to delete admin user?")) {
            cmsDeleteAdminUserPromise(
                self.props.account.Id,
                data.Id
            ).then(function () {
                toast.success("User deleted");
            }, function (err) {
                toast.error("Unable to delete user: " + getErrMsg(err));
            }).finally(function () {
                self.tableRef.current.forceTableUpdate();
            })
        }
    }

    render() {
        var self = this;

        return (
            <ExpandingTable
                fetchData={self.fetchData}
                filters={self.state.filters}
                searchText={self.state.searchText}
                update={self.state.count}
                columns={[
                    {
                        id: "name",
                        Header: "Name",
                        accessor: function (d) {
                            return d.FirstName + ' ' + d.LastName;
                        },
                        Filter: ({ filter, onChange }) => self.tableFilter(filter, onChange, "name")
                    },
                    {
                        id: "email",
                        Header: "Email",
                        accessor: function (d) {
                            if (d.Email) {
                                return d.Email;
                            } else {
                                return d.UPN;
                            }
                        },
                        Filter: ({ filter, onChange }) => self.tableFilter(filter, onChange, "email")
                    },
                    {
                        id: "roles",
                        Header: "Roles",
                        accessor: function (d) {
                            return d.RoleNames.join(", ");
                        },
                        filterable: false
                    },
                    {
                        id: "delete",
                        Header: "",
                        className: "text-right",
                        colWidth: 50,
                        accessor: function (d) {
                            return (
                                <button onClick={(e) => self.deleteAdminUser(e, d)} className="btn btn-xs btn-default">
                                    <i className="fa-solid fa-trash"></i>
                                </button>
                            );
                        },
                        filterable: false
                    }
                ]
                }
                sortBy={{
                    Column: 'name',
                    SortDirection: 'Descending',
                }}
                loadingText={"Loading..."}
                noDataText={"You don't have any administrative users yet."}
                showPagination={true}
                pageSizeOptions={[10, 20, 50]}
                className="-striped -highlight"
                SubComponent={row => {
                    return (
                        <EditAdminUserForm
                            closeForm={() => self.closeAllRows()}
                            adminUser={row.original}
                            closeButtonText={"Close"}
                            submitButtonText={"Save"}
                        />
                    );
                }}
            />
        );
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AdminUserReactTable);
