import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { cmsDeleteAccount, cmsGet, cmsGetPromise } from '../CallMSAPI.js';
import { canAccess } from '../CallMSUIHelpers.js';
// required for show/hide delete button...
import { getMsalConfig } from '../MSALAuthProvider';
import { showConsoleLogs } from '../CallMSUIHelpers.js';
import ExpandingTable from '../ExpandingReactTable';
import { toast } from 'react-toastify';
import AccountRow from './AccountRow';
import { connect } from 'react-redux';
var _ = require('lodash');

class AccountsReactTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            // Used by table
            pageCount: -1,
            canDelete: getMsalConfig().app.allowDeleteAccount,
            filters: [],
            searchText: [],

            unsavedChanges: false,
        }
        
        this.fetchData = this.fetchData.bind(this);
        this.tableFilter = this.tableFilter.bind(this);
        this.deleteAccountClickHandler = this.deleteAccountClickHandler.bind(this);
        this.setUnsavedChanges = this.setUnsavedChanges.bind(this);
        this.clearChanges = this.clearChanges.bind(this);
    }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     var self = this;

    //     if (prevProps.location.pathname != this.props.location.pathname || prevProps.account.Id !== this.props.account.Id) {
    //             self.setState({filters: []}, () => {
    //                 return
    //             });
    //     }
    // }

    fetchData(filterValues, searchText, pageSize, page, sortSettings) {
        var self = this;

        var apiParams = {
            accountId: self.props.account.Id,
            objectType: 'accounts'
        };

        // Bail if we can't make the API call...
        if (apiParams.accountId === undefined) {
            return;
        }

        if (pageSize) {
            apiParams['pageSize'] = pageSize;
        }

        if (page) {
            apiParams['currentPage'] = page;
        }

        if(sortSettings) {
            apiParams['SortBy'] = sortSettings.Column;
            apiParams['SortDirection'] = sortSettings.SortDirection;
        }

        if(searchText) {
            if (searchText.name ) {
                apiParams['searchText'] = searchText.name;
            }
            if (searchText.externalid ) {
                apiParams['externalIdSearch'] = searchText.externalid;
            }
        }

        return cmsGetPromise(apiParams);
    }

    tableFilter(filter, onChange, type) {
        var self = this;
        var opts = null;

        return (
            <input
                onChange={event => {
                    let temp =[];
                    temp[type] = event.currentTarget.value;
                    self.setState({searchText:  temp});
                }}
                style={{ width: "100%" }}
                value={self.state.searchText[type] ? self.state.searchText[type] : ''}
            />
        );
    }

    deleteAccountClickHandler(e, row) {
        var self = this;
        e.preventDefault();
        if (window.confirm("Are you sure you want to delete account '" + row.Name + "'?")) {
            cmsDeleteAccount(
                row.Id,
                function (ok) {
                    toast.success("Account '" + row.Name + "' deleted OK");
                    self.tableRef.current.forceTableUpdate();
                },
                function (err) {
                    toast.error(err);
                }
            );
        }
    }

    goToAccounts = (e, id = '') => {
        e.preventDefault();
        this.props.history.push(this.accountsRoute(id));
    }

    accountsRoute = (id = '') => {
        return `/accounts/${id}`;
    }

    render() {
        var self = this;

        return (
            <ExpandingTable
                fetchData={self.fetchData}
                columns={[
                    {
                        id: "name",
                        Header: "Name",
                        accessor: function (d) {
                            var prefix = null;
                            if (self.state.canDelete && d.NumChildren === 0) {
                                prefix = (
                                    <a href={`/accounts/${d.Id}`} className="btn btn-default btn-xs btn--domain-delete" onClick={(e) => self.deleteAccountClickHandler(e, d)}>
                                        <i className="fa-solid fa-trash"></i>
                                    </a>
                                );
                            }
                            return (
                                <div className="account-tablecell">
                                    <div className="accountName-tablecell">{d.Name}</div>
                                    <div className="deleteAccount-tablecell">{prefix}</div>
                                </div>
                            );
                        },
                        sortable: true,
                        Filter: ({ filter, onChange }) => self.tableFilter(filter, onChange, "name")
                    },
                    {
                        id: "externalid",
                        Header: "Account Reference",
                        accessor: function (d) {
                            return (
                                <span>{d.ExternalId}</span>
                            );
                        },
                        sortable: false,
                        filterable: true,
                        Filter: ({ filter, onChange }) => self.tableFilter(filter, onChange, "externalid")
                    },
                    {
                        id: "selectaccount",
                        Header: "Account Link",
                        accessor: function (d) {
                            // Does the user have subscription contributor?
                            // _or_ are there children?
                            if (d.NumChildren === 0 && !canAccess('AccountPortal', self.props.baseAccountInfo.Roles)) {
                                // No link...
                                return null;
                            }

                            var prefix = null;
                            if (d.NumChildren > 0) {
                                prefix = <span>({d.NumChildren} Accounts)</span>
                            }
                            return (
                                <a href={self.accountsRoute(d.Id)} onClick={(e) => {
                                    self.setState({ searchText: []}, () => self.goToAccounts(e, d.Id));
                                    }} to={"/accounts/" + d.Id}>
                                    <i className="fa-solid fa-list-check"></i> {prefix}
                                </a>
                            );
                        },
                        sortable: false,
                        filterable: false,
                        className: 'text-center'
                    },
                    {
                        id: "useaccount",
                        Header: "Portal Link",
                        accessor: function (d) {
                            return (<a href={"/portal/" + d.Id + "/users"} data-testid='customerPortalId' onClick={() => 
                                self.setState({ searchText: []})} >Customer Portal</a>);
                        },
                        sortable: false,
                        filterable: false,
                        className: 'text-center'
                    }
                ]
                }
                sortBy={{
                    Column: 'name',
                    SortDirection: 'Ascending'
                }}
                loadingText={"Loading..."}
                noDataText={"You don't have any sub accounts."}
                showPagination={true}
                filters={this.state.filters}
                searchText={this.state.searchText}
                update={`${this.props.account.Id}+${this.props.lastUpdate}`}
                fullRefresh={true}
                pageSizeOptions={[10, 20, 50]}
                className="-striped -highlight"
                SubComponent={(row, toggleExpanded) => {
                    return (
                        <AccountRow
                            hasUnsavedChanges={self.state.unsavedChanges}
                            setUnsavedChanges={self.setUnsavedChanges}
                            clearChanges={self.clearChanges}
                            closeCallback={(row) => {
                                toggleExpanded()
                            }}
                            targetAccountOverview={row.original}
                        />
                    );
                }}
            />
        );
    }

    setUnsavedChanges(val, cb) {
        this.setState({
            unsavedChanges: val
        }, function () {
            cb && cb();
        });
    }

    clearChanges(cb) {
        this.setState({
            unsavedChanges: false
        }, function () {
            cb && cb();
        });
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account,
        baseAccountInfo: account.baseAccountInfo
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {}
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountsReactTable));
