import React, { Component } from 'react';
import {
    cmsGetStatePromise, cmsDeleteService, cmsServiceEnableSyncWrapper, cmsStartOAuthServiceSync, cmsGetCountries, cmsGetAccountServicePromise,
    getErrMsg,
    cmsServiceMigrate,
    cmsEditServicePromise,
    cmsGetSyncModuleDefaultSettings,
    cmsServiceDisableSync,
    cmsEditAccountPromise,
} from '../CallMSAPI.js';
import { toast } from 'react-toastify';
import DNSRecords from './DNSRecords';
import MicrosoftServiceNew from './MicrosoftServiceNew';
import MicrosoftManualSteps from './MicrosoftManualSteps';
import { Formik } from 'formik';
import { isSystemOwner } from '../CallMSUIHelpers';
import MicrosoftAdvancedSyncOptions from './MicrosoftAdvancedSyncOptions';
import { SubmitButton } from '../FormHelpers';
import { LargeTenantModeRow, EnableSyncRow } from './ServiceFormHelpers';
import { openPopupWindow } from '../ExternalAuthProvider.js';
import { extractSpecificLicence } from '../LicenceHelpers';

import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import {
    TeachingBubble, MessageBar, MessageBarType,
    PrimaryButton, DefaultButton,
} from 'office-ui-fabric-react';

import { connect } from 'react-redux';
import * as actions from '../store/actions/index';
import SbcReallocateDropDown from '../components/service/SbcReallocateDropDown.js';
import { TooltipHost } from '@fluentui/react';
import CallingEssentialsRow from '../components/service/CallingEssentialsRow.js';
import NextGenSyncRow from '../components/service/NextGenSyncRow.js';
import NextGenPhoneAppRow from '../components/service/NextGenPhoneAppRow.js';

var _ = require('lodash');

class MicrosoftServicePanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hideDialog: true,
            serviceCountry: {},
            serviceState: {},
            loadFullService: false,
            fullService: null,
            hideUpgrade: true,
            hideDowngrade: true,
            showHelp: false,
            showCallingEssentialsHelp: false,
            showTenantWarningDialog: false,
            isNextGenEnabled: props.account.IsBetaFeaturesEnabled ? props.account.IsBetaFeaturesEnabled.includes('ENABLENEXTGENSYNC') : false
        };

        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);

        this.triggerMigration = this.triggerMigration.bind(this);
        this.showUpgrade = this.showUpgrade.bind(this);
        this.hideUpgrade = this.hideUpgrade.bind(this);
        this.showDowngrade = this.showDowngrade.bind(this);
        this.hideDowngrade = this.hideDowngrade.bind(this);

        this.toggleHelp = this.toggleHelp.bind(this);
        this.dismissHelp = this.dismissHelp.bind(this);
        this.refreshFullService = this.refreshFullService.bind(this);
        this.onToggleChange = this.onToggleChange.bind(this);
        this.shouldIncludePrefix = this.shouldIncludePrefix.bind(this);
        this.getFormDefaults = this.getFormDefaults.bind(this);
        this.enableSync = this.enableSync.bind(this);
        this.buildPopupCb = this.buildPopupCb.bind(this);
    }

    buildPopupCb() {

        var popup = openPopupWindow('about:blank', "Portal Auth");
        return popup;
    }

    toggleHelp() {
        this.setState(prevState => ({ showHelp: !prevState.showHelp }));
    }

    dismissHelp() {
        this.setState({ showHelp: false });
    }

    componentDidMount() {
        this.loadFullService();
    }

    componentDidUpdate(prevProps) {

        if (
            (this.props.currentService && !prevProps.currentService)
            || (
                this.props.currentService && prevProps.currentService
                && this.props.currentService.Id !== prevProps.currentService.Id
            )
        ) {
            this.refreshFullService();
        }
    }

    refreshFullService() {
        var self = this;

        this.setState({
            loadFullService: false
        }, function () {
            self.loadFullService();
        });
    }

    loadFullService(extraCb = null) {
        var self = this;
        if (self.state.loadFullService === false && self.props.currentService) {
            self.setState({
                loadFullService: true
            }, function () {
                cmsGetAccountServicePromise(
                    self.props.account.Id, self.props.currentService.Id
                ).then(function (result) {

                    self.setState({
                        fullService: result.data,
                    }, function () {
                        self.postServiceLoad();
                        extraCb && extraCb(self.state.fullService);
                    });

                });
            })

        }
    }

    postServiceLoad() {
        var self = this;
        if (self.state.fullService && self.state.fullService.CountryId) {
            cmsGetCountries(
                self.props.account.Id,
                {
                    CountryId: self.state.fullService.CountryId
                },
                function (data) {
                    if (data.Results && data.Results.length) {
                        self.setState({
                            serviceCountry: data.Results[0]
                        });
                    }
                },
                function (err) {
                    toast.error("Unable to retrieve country with ID: " + self.state.fullService.CountryId);
                }
            );
        }
        if (self.state.fullService && self.state.fullService.StateId) {
            cmsGetStatePromise(
                self.props.account.Id,
                {
                    StateId: self.state.fullService.StateId
                }
            ).then(
                function (response) {
                    var data = response.data;
                    if (data.Results && data.Results.length) {
                        self.setState({
                            serviceState: data.Results[0]
                        });
                    }
                },
                function (err) {
                    toast.error("Unable to retrieve state with ID: " + self.state.fullService.StateId);
                }
            );
        }
    }

    showModal() {
        this.setState({ hideDialog: false });
    }

    hideModal() {
        this.setState({ hideDialog: true });
    }

    showUpgrade() {
        this.setState({ hideUpgrade: false });
    }

    hideUpgrade() {
        this.setState({ hideUpgrade: true });
    }

    showDowngrade() {
        this.setState({ hideDowngrade: false });
    }

    hideDowngrade() {
        this.setState({ hideDowngrade: true });
    }

    refetchServices = () =>
    {
        //Using Redux Action...
        this.props.refetchServices();
    }

    deleteServiceClick(e) {
        var self = this;
        e.preventDefault();
        self.setState({ hideDialog: true });

        cmsDeleteService(
            this.props.account.Id,
            this.props.currentService.Id,
            function () {
                toast.success("Service deleted");
                
                self.refetchServices();
            },
            function (err) {
                toast.error("Unable to delete service");
            }
        );
    }


    enableSync(isSyncEnabled, service, serviceSave) {
        var self = this;

        if (isSyncEnabled && service && !service.SyncEnabled) {

            var data = {
                'OverwriteData': true,
                'SyncSettings': {}
            };

        var okCb = function (ok) {

            self.refetchServices();

            self.refreshFullService();
            toast.success("Service sync enabled");
        };

        var errorCb = function (err) {
           
            self.refetchServices();

            self.refreshFullService();
            toast.error(err);
        };

            cmsServiceEnableSyncWrapper(
                self.props.account.Id,
                self.props.currentService,
                data,
                false,
                function (ok) {

                    cmsGetSyncModuleDefaultSettings(self.props.account.Id).then(function (res) {
                        var syncIdObj = _.find(res.data, { Key: 'SyncClientId' });
                        var syncId = null;
                        if (syncIdObj) {
                            syncId = syncIdObj.Value;
                        }
                        let isAutoSync = self.state.fullService ? self.state.fullService.TeamsSettings.IsAutoSyncEnabled : null;
                        cmsStartOAuthServiceSync(
                            self.props.account.Id,
                            self.props.currentService,
                            {
                                firstSync: true
                            },
                            null,
                            syncId,
                            isAutoSync
                        ).then(okCb, errorCb).finally(serviceSave);
                    })
                },
                errorCb
            )
        } else if (!isSyncEnabled && service && service.SyncEnabled) {
            cmsServiceDisableSync(
                self.props.account.Id,
                service.Id,
                function (ok) {
                    toast.success("Service sync is now disabled.");
                    self.props.updateServicesTrigger && self.props.updateServicesTrigger();
                    self.refreshFullService();
                    if (serviceSave) {
                        serviceSave();
                    }
                },
                function (err) {
                    toast.error("Service sync failed to update: " + err)
                }
            );
        } else {
            if (serviceSave) {
                serviceSave();
            }
        }
    }

    triggerMigration(e, variant, syncEnabled, dir) {
        var self = this;
        e.preventDefault();
        var message = '';
        if (dir === 'upgrade') { message = "Service upgrade initiated. " }
        else if (dir === 'downgrade') { message = "Service downgrade initiated. " }

        cmsServiceMigrate(
            self.props.account.Id,
            self.state.fullService.Id,
            {
                queryString: 'ServiceVariantId='+ variant.Id
            },
            function (ok) {
                self.hideUpgrade();
                
                self.refetchServices();

                toast.success(message + (syncEnabled ? "A new sync will now start." : "Please configure the service as per Teams OneClick Admin Setup Guide"));
                self.setState({
                    loadFullService: false
                }, function () {
                    self.loadFullService(
                        function (sObj) {
                            if (syncEnabled) {
                                return cmsStartOAuthServiceSync(
                                    self.props.account.Id, sObj, null, null, null, sObj.TeamsSettings.IsAutoSyncEnabled
                                );
                            }
                        }
                    );
                });
            },
            function (err) {
                self.hideUpgrade();
                toast.error(getErrMsg(err));
            }
        )
    }

    onToggleChange = (e, checked, containerId, setFieldValue, fieldName) => {
        var self = this;
        var elements = document.getElementsByClassName(containerId);
        for (var i = 0; i < elements.length; i++) {
            var element = elements.item(i)
            element.style.display = checked
                ? "block"
                : "none";
        }
        setFieldValue(fieldName, checked);
    }

    shouldIncludePrefix(newValue, phoneNumberOpts) {
        if (
            phoneNumberOpts
            && phoneNumberOpts.hasOwnProperty('prefix')
            && (!newValue
                || (
                    newValue && (newValue.includes(phoneNumberOpts.prefix) || !newValue.includes('+'))
                )
            )) {
            return true;
        }
        else {
            return false;
        }
    }

    getFormDefaults() {
        var self = this;
        var defaults = {
            LargeTenantMode: false,
            SyncSettings: {},
            TeamsSettings: {},
            PhoneAppToggle: false,
            PhoneAppOnlyToggle: false,
            GroupIdListNative: null,
            GroupIdListCE: null,
            GroupIdListNative: null,
            NextGenSyncToggle: this.props.account.IsBetaFeaturesEnabled ? this.props.account.IsBetaFeaturesEnabled.includes('ENABLENEXTGENSYNC') : false,
            IsSingleStage: this.props.account.IsBetaFeaturesEnabled ? this.props.account.IsBetaFeaturesEnabled.includes('SINGLESTAGEDIALING') : false,
            ZeroTouchProvisioning: false
        };
        defaults.ShowAutoSyncToggle = defaults.NextGenSyncToggle; // Only want to show Auto Sync toggle once next gen sync has been enabled and saved

        if (self.state.fullService && self.state.fullService.SyncSettings) {
            // Only keep it if it's truthy (not null, not empty string)
            var opt = self.state.fullService.SyncSettings.find(x => x.Key == 'GroupIdListNative');
            if (opt && opt.Value) {
                defaults['LargeTenantMode'] = true;
                defaults['GroupIdListNative'] = opt.Value;
            }
            var optCE = self.state.fullService.SyncSettings.find(x => x.Key == 'GroupIdListCE');
            if (optCE && optCE.Value) {
                defaults['LargeTenantMode'] = true;
                defaults['GroupIdListCE'] = optCE.Value;
            }

            defaults['SyncEnabled'] = self.state.fullService.SyncEnabled;

            var psopt = self.state.fullService.SyncSettings.find(x => x.Key == 'PSNamePrefix');
            if (psopt && psopt.Value) {
                defaults['PSNamePrefix'] = psopt.Value;
            }

            var psUsOpt = self.state.fullService.SyncSettings.find(x => x.Key == 'PSUsageLocations');
            if (psUsOpt && psUsOpt.Value) {
                defaults['PSUsageLocations'] = psUsOpt.Value;
            }

            // Legacy check incase they have the old key: 'UserFilterGroupName'
            if (!defaults['LargeTenantMode']) {
                var opt = self.state.fullService.SyncSettings.find(x => x.Key == 'UserFilterGroupName');
                if (opt && opt.Value) {
                    defaults['LargeTenantMode'] = true;
                    // don't set it, sync will do the move for us
                    //defaults['GroupIdListNative'] = opt.Value;
                }
            }

            if (self.state.fullService && self.state.fullService.TeamsSettings) {
                if (self.state.fullService.TeamsSettings.IsAutoSyncEnabled && defaults.ShowAutoSyncToggle){
                    defaults.AutoSyncToggle = true;
                }
                if (self.state.fullService.TeamsSettings.IsPhoneAppEnabled) {
                    switch (self.state.fullService.TeamsSettings.IsPhoneAppEnabled) {
                        case 'Disabled':
                            defaults['PhoneAppToggle'] = false;
                            defaults['PhoneAppOnlyToggle'] = false;
                            break;
                        case 'Enabled':
                            defaults['PhoneAppToggle'] = true;
                            defaults['PhoneAppOnlyToggle'] = false;
                            break;
                        case 'PhoneAppOnly':
                            defaults['PhoneAppToggle'] = true;
                            defaults['PhoneAppOnlyToggle'] = true;
                            break;
                    }
                }
            }
        }

        return defaults;
    }

    getTeamsAdvancedOptions = (isFormDisabled) => {
        var self = this;
        var phoneNumberPrefix = self.state.serviceCountry && self.state.serviceCountry.DialPrefix ? self.state.serviceCountry.DialPrefix : null;
        var phoneNumberOpts = self.state.serviceCountry && self.state.serviceCountry.DialPrefix ? { prefix: "+" + phoneNumberPrefix } : null;
        if(!self.state.fullService) {
            return null;
        }
        return (
            <>
                <Formik
                    enableReinitialize={true}
                    initialValues={self.getFormDefaults()}
                    validate={values => {
                        let errors = {};

                        if (values.PSUsageLocations && !/^[A-Za-z]+(?:\s*,\s*[A-Za-z]+)*$/i.test(values.PSUsageLocations)) {
                            errors.PSUsageLocations = 'This value should only contain Alphabetic characters, and commas.';
                        }
                        return errors;
                    }}
                    onSubmit={(originalValues, { setSubmitting, setErrors, resetForm }) => {
                        var formik = this;

                        var values = _.cloneDeep(originalValues);

                        if (values.PSNamePrefix) {
                            delete values.PSNamePrefix;
                        }

                        values.SyncSettings = {};
                        if (values.LargeTenantMode === false) {
                            values.SyncSettings['GroupIdListNative'] = null;
                        } else {
                            values.SyncSettings['GroupIdListNative'] = values.GroupIdListNative;
                        }
                        if ((values.PhoneAppToggle || values.PhoneAppOnlyToggle) && values.LargeTenantMode === true) {
                            values.SyncSettings['GroupIdListCE'] = values.GroupIdListCE;
                        } else {
                            values.SyncSettings['GroupIdListCE'] = null;
                        }

                        if(values.PSUsageLocations) {
                            values.SyncSettings['PSUsageLocations'] = values.PSUsageLocations;
                        } 

                        var phoneAppEnabled = 'Disabled';
                        if (!values['PhoneAppToggle']) {
                            phoneAppEnabled = 'Disabled';
                        } else if (values['PhoneAppToggle'] && !values['PhoneAppOnlyToggle']) {
                            phoneAppEnabled = 'Enabled';
                        } else if (values['PhoneAppToggle'] && values['PhoneAppOnlyToggle']) {
                            phoneAppEnabled = 'PhoneAppOnly';
                        }
                        values['TeamsSettings'] = {
                            IsPhoneAppEnabled: phoneAppEnabled,
                            NextGenSyncEnabled: values.NextGenSyncToggle === true,
                            IsSingleStage: values.IsSingleStage === true
                        };
                        delete values.IsSingleStage;
                        delete values.NextGenSyncToggle;
                        delete values.PhoneAppToggle;
                        delete values.PhoneAppOnlyToggle;
                        delete values.PhoneAppEnabled;
                        delete values.GroupIdListNative;
                        delete values.LargeTenantMode;
                        delete values.PSUsageLocations;
                        delete values.AutoSyncToggle;
                        delete values.ShowAutoSyncToggle;

                        if (!values.SyncEnabled) {
                            values['TeamsSettings'] = {
                                IsPhoneAppEnabled: 'Disabled',
                                NextGenSyncEnabled: false
                            };
                            for (var key in values['SyncSettings']) {
                                values.SyncSettings[key] = null;
                            }
                        }

                        var service = self.state.fullService;
                        var isSyncEnabled = values.SyncEnabled;
                        
                        let promises = [];
                        promises.push(cmsEditServicePromise(self.props.account.Id, self.state.fullService.Id, values));

                        self.enableSync(isSyncEnabled, service,
                            () => {
                                return Promise.all(promises).then(res => {
                                    toast.success('Service saved successfully');
                                    setSubmitting(false);
                                    self.props.refreshAccount();
                                    self.refreshFullService();
                                }).catch(err => {
                                    setSubmitting(false);
                                    toast.error(getErrMsg(err));
                                });
                            }
                        );

                    }}>
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue
                        /* and other goodies */
                    }) => {

                        var isCallingEssentialsEnabled = false;
                        var isPAEnabled = false;
                        if (self.state.fullService
                            && self.state.fullService.TeamsSettings
                            && self.state.fullService.TeamsSettings.IsPhoneAppEnabled
                            && self.state.fullService.TeamsSettings.IsPhoneAppEnabled == "Enabled")
                        {
                            isPAEnabled = true;
                        }
                        var phoneAppLic = extractSpecificLicence(self.props.account.Licences, 'USER-PHONEAPP');
                        if (phoneAppLic || isPAEnabled) {
                            isCallingEssentialsEnabled = true;
                        }

                        var useCEGroups = (values.PhoneAppToggle || values.PhoneAppOnlyToggle) && isCallingEssentialsEnabled ? true : false;

                        var service = self.state.fullService;

                        var syncEnabledToggle = null;
                        if (service.SyncEnabled) {
                            var syncInfo = null;
                            var syncOverwriteInfo = null;

                            if (service.SyncSettings) {
                                var syncDomain = _.find(service.SyncSettings, { Key: 'TenantDomain' });
                                var syncTenant = _.find(service.SyncSettings, { Key: 'TenantId' });

                                if (syncDomain) {
                                    syncInfo = (
                                        <>
                                            <p className="text-muted">
                                                <span title={syncTenant ? syncTenant.Value : ''}>{syncDomain.Value}</span>
                                            </p>
                                            <EnableSyncRow
                                                values={values}
                                                errors={errors}
                                                touched={touched}
                                                setFieldValue={setFieldValue}
                                                disabled={isFormDisabled}
                                                account={self.props.account} />
                                            {syncOverwriteInfo}
                                        </>
                                    );
                                }
                            }
                        }
                        syncEnabledToggle = service.SyncEnabled ? (
                            <>
                                {syncInfo}
                            </>
                        ) : (
                                <EnableSyncRow
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    setFieldValue={setFieldValue}
                                    disabled={isFormDisabled}
                                    account={self.props.account} />
                        );

                        var syncToggle = null;
                        if (service.Variant.SyncSupported) {
                            syncToggle = (
                                <div className="service-col">
                                    <div className="service-th">
                                        <strong>Sync {service.SyncEnabled ? "Enabled" : "Disabled"}</strong>
                                    </div>
                                    <div className="service-td">
                                        {syncEnabledToggle}
                                    </div>
                                </div>
                            );
                        }

                        var service = self.state.fullService;

                        var upgradeButton = null;
                        var downgradeButton = null;
                        if (service.Variant.ServiceCode === 'teams'
                            && self.state.fullService.Variant.Code !== 'TEAMSAUDIOCODES'
                            && self.state.fullService.Variant.Code !== 'TEAMSRIBBON'
                        ) {
                            // Do we have the new variant available?
                            var ser = _.find(self.props.account.AvailableServices, function (s) { return s.Code === 'teams' });
                            if (ser) {

                                var variant = _.find(ser.Variants, function (s) { return s.Code === 'TEAMSAUDIOCODES' });
                                if (variant) {

                                    var helpText = (<p>
                                        This will upgrade your account to the One-Click 2nd Gen Teams connector and start a new Sync process to update your Office 365 configuration.
                                        Your Teams calls will immediately stop working until the Office 365 configuration has been updated. This usually takes between 10 minutes and a few hours, depending on the load on Microsoft Office 365.
                                        Please ensure you have an unassigned licence (Business Essentials, E1, E3, E5 etc) prior to commencing the upgrade.
                                    </p>);

                                    if (!service.SyncEnabled) {
                                        helpText = <p>
                                            This will upgrade you to the latest One-Click
                                            2nd Gen Teams connector. Your Teams calls will
                                            immediately stop working until you update the
                                            Office 365 configuration. This involves
                                            configuring new custom domains in Office 365
                                            and running PowerShell to setup new voice
                                            routes. Please refer to the Teams Admin Setup
                                            Guide, chapter 5.
                                        </p>;
                                    }

                                    upgradeButton = (
                                        <>
                                            &nbsp;
                                            &nbsp;
                                            <button className="btn btn-primary" onClick={self.showUpgrade}>
                                                Upgrade Teams Connector
                                            </button>
                                            <button className="btn btn-link btn--faux-link" onClick={(e) => { e.preventDefault(); self.toggleHelp() }}>
                                                <i className="fa-solid fa-question-circle" id={"helpForUpgrade"}></i>
                                            </button>
                                            {self.state.showHelp ?
                                                <TeachingBubble
                                                    target={'#helpForUpgrade'}
                                                    hasCondensedHeadline={true}
                                                    onDismiss={() => self.dismissHelp()}
                                                    hasCloseIcon={true}
                                                    closeButtonAriaLabel="Close"
                                                    headline={'Upgrade Teams Connector'}
                                                >
                                                    <>
                                                        <p>Clicking this button will upgrade your account to the One-Click 2nd Gen Teams connector.</p>

                                                        <p>When you click the button your Teams calls will stop working until the
                                                        Office 365 configuration has been updated. This usually takes between 10
                                                        minutes and a few hours, depending on the load on Microsoft Office 365.
                                                        </p>
                                                        <p>The newer connector provides several benefits including:</p>

                                                        <ul>
                                                            <li>Wider range of codec support, including SILK and G729</li>
                                                            <li>Enhanced transfer support</li>
                                                            <li>Support for PBX/Trunk Music-On-Hold</li>
                                                            <li>Faster call response times</li>
                                                        </ul>

                                                    </>
                                                </TeachingBubble>
                                                : null}

                                            <Dialog
                                                hidden={self.state.hideUpgrade}
                                                onDismiss={self.hideUpgrade}
                                                dialogContentProps={{
                                                    type: DialogType.normal,
                                                    title: 'Upgrade Service',
                                                    showCloseButton: false
                                                }}
                                                modalProps={{
                                                    styles: { main: { minWidth: 500, maxWidth: 600 } },
                                                }}
                                            >
                                                {helpText}
                                                <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
                                                    Warning: During the upgrade process Teams users will be unable to make or receive phone calls.
                                                </MessageBar>
                                                <DialogFooter>
                                                    <PrimaryButton onClick={(e) => this.triggerMigration(e, variant, service.SyncEnabled, 'upgrade')} text="Upgrade Now" />
                                                    <DefaultButton onClick={self.hideUpgrade} text="Cancel" />
                                                </DialogFooter>
                                            </Dialog>
                                        </>
                                    );
                                }
                            }
                        }

                        if (service.Variant.ServiceCode === 'teams'
                            && self.state.fullService.Variant.Code === 'TEAMSAUDIOCODES'
                        ) {
                            // Do we have the new variant available?
                            var dser = _.find(self.props.account.AvailableServices, function (s) { return s.Code === 'teams' });
                            if (dser) {
                                var downgradeVariant = _.find(dser.Variants, function (s) { return s.Code === 'TEAMSNATIVESPDOM' });
                                if (downgradeVariant) {

                                    var helpText = (<p>
                                        This will downgrade your account to the One-Click 1st Gen Teams connector.
                                        Your Teams calls will immediately stop working until you click the Sync Now button and the Office 365 configuration has been updated. This usually takes between 10 minutes and a few hours, depending on the load on Microsoft Office 365.
                                        Please ensure you have an unassigned licence (Business Essentials, E1, E3, E5 etc) prior to commencing the upgrade.
                                    </p>);

                                    if (!service.SyncEnabled) {
                                        helpText = <p>
                                            This will downgrade you to the One-Click
                                            1st Gen Teams connector. Your Teams calls will
                                            immediately stop working until you update the
                                            Office 365 configuration. This involves
                                            configuring new custom domains in Office 365
                                            and running PowerShell to setup new voice
                                            routes. Please refer to the Teams Admin Setup
                                            Guide, chapter 5.
                                        </p>;
                                    }

                                    downgradeButton = (
                                        <>
                                            &nbsp;
                                            &nbsp;
                                            <button className="btn btn-primary" onClick={self.showDowngrade}>
                                                Downgrade Teams Connector
                                            </button>

                                            <Dialog
                                                hidden={self.state.hideDowngrade}
                                                onDismiss={self.hideDowngrade}
                                                dialogContentProps={{
                                                    type: DialogType.normal,
                                                    title: 'Downgrade Service',
                                                    showCloseButton: false
                                                }}
                                                modalProps={{
                                                    styles: { main: { minWidth: 500, maxWidth: 600 } },
                                                }}
                                            >
                                                <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
                                                    Warning: During the downgrade process Teams users will be unable to make or receive phone calls.
                                                </MessageBar>
                                                <DialogFooter>
                                                    <PrimaryButton onClick={(e) => this.triggerMigration(e, downgradeVariant, service.SyncEnabled, 'downgrade')} text="Downgrade Now" />
                                                    <DefaultButton onClick={self.hideDowngrade} text="Cancel" />
                                                </DialogFooter>
                                            </Dialog>
                                        </>
                                    );
                                }
                            }
                        }

                        var domainNameRow = null;
                        if (service.Domains.length) {
                            domainNameRow = (
                                <div className="service-col">
                                    <div className="service-th">
                                        <strong>{service.Domains.length <= 1 ? 'Domain' : 'Domains'}</strong>
                                    </div>
                                    <div className="service-td">
                                        {service.Domains.map(
                                            function (domain, index) {
                                                return (<p key={index}>{domain.DomainName}</p>);
                                            }
                                        )}
                                    </div>
                                </div>
                            );
                        }

                        var countryInner = null;
                        if (!_.isEmpty(self.state.serviceCountry)) {
                            countryInner = self.state.serviceCountry.Name;
                        }

                        if (!_.isEmpty(self.state.serviceState)) {
                            countryInner = (<>
                                {self.state.serviceState.Name}
                                &nbsp;({self.state.serviceState.CountryName})
                                </>);
                        }

                        var federatedDomain = null;
                        if (service.Variant.ServiceCode === 'sfbfed' && self.props.account.BrandDomain) {
                            federatedDomain = (
                                <div className="service-col">
                                    <div className="service-th">
                                        <strong>Federated Domain</strong>
                                    </div>
                                    <div className="service-td">
                                        {self.props.account.BrandDomain}
                                    </div>
                                </div>
                            );
                        }

                        return (
                            <>
                                <form className="form update-teams-service-form" onSubmit={handleSubmit}>
                                    <div className="service-part-wrapper">

                                        {domainNameRow}

                                        {countryInner
                                            ? <div className="service-col">
                                                <div className="service-th">
                                                    <strong>Country</strong>
                                                </div>
                                                <div className="service-td">{countryInner}</div>
                                            </div>
                                            : null
                                        }

                                        {federatedDomain}

                                        <div className="service-col">
                                            <div className="service-th">
                                                <TooltipHost
                                                    content={`Account Id: ${self.props.account.Id}, Service Id: ${self.props.currentService.Id}`}
                                                    id='Variant'
                                                >
                                                    <strong
                                                        className='default'
                                                        aria-describedby='Variant'
                                                    >
                                                        Variant
                                                    </strong>
                                                </TooltipHost>
                                            </div>
                                            <div className="service-td">
                                                {service.Variant.Name}
                                                {upgradeButton}
                                                {downgradeButton}
                                            </div>
                                        </div>

                                        {syncToggle}
                                    </div>

                                    {service.Variant.ServiceCode === 'teams' ?
                                        <>
                                            {values.SyncEnabled ? <hr /> : null}

                                            {isCallingEssentialsEnabled && values.SyncEnabled &&
                                                <CallingEssentialsRow
                                                    values={values}
                                                    errors={errors}
                                                    touched={touched}
                                                    refreshFullService={this.refreshFullService}
                                                    setFieldValue={setFieldValue}
                                                    fullService={self.state.fullService}
                                                    disabled={isFormDisabled}
                                                    {...self.props} />
                                            }
                                            {self.state.fullService && self.state.fullService.SyncEnabled && values.SyncEnabled
                                                ?
                                                    <NextGenSyncRow account={self.props.account} values={values} errors={errors} touched={touched} setFieldValue={setFieldValue}/>
                                                : null
                                            }

                                            <NextGenPhoneAppRow account={self.props.account} values={values} errors={errors} touched={touched} setFieldValue={setFieldValue}/>

                                            {values.SyncEnabled ?
                                                <LargeTenantModeRow
                                                    fullService={self.state.fullService}
                                                    values={values}
                                                    setFieldValue={setFieldValue}
                                                    disabled={isFormDisabled}
                                                    isCallingEssentialsEnabled={useCEGroups}
                                                />
                                                : null}
                                            {isSystemOwner(self.props.baseAccountInfo.Roles) &&
                                                <div className='row'>
                                                    <div className='col-sm-12'>
                                                        <SbcReallocateDropDown
                                                            serviceId={self.state.fullService.Id}
                                                            onSubmit={self.refreshFullService}
                                                        />
                                                    </div>
                                                </div>
                                            }

                                            {values.SyncEnabled ?
                                                <MicrosoftAdvancedSyncOptions
                                                    setFieldValue={setFieldValue}
                                                    accountBrandName={self.props.account.Brand}
                                                    values={values}
                                                    touched={touched}
                                                    errors={errors}
                                                    readOnly={true} />
                                                : null}

                                            <div className="row" >
                                                <SubmitButton className={"btn btn-primary mb-2 right-action-button"} isSubmitting={isSubmitting} >Save</SubmitButton>
                                            </div>
                                        </>
                                        : null}

                                </form>
                                <hr />
                            </>
                        );
                    }}
                </Formik>
            </>
        )
    }

    render() {
        var self = this;

        if (self.props.currentService) {
            if (self.state.fullService === null) {
                return <p>Loading full service data...</p>;
            }
            var service = self.state.fullService;

            var dnsWrapper = null;
            if (self.state.fullService.Domains.length
                && self.state.fullService.Variant.Code !== 'TEAMSNATIVESPDOM'
                && self.state.fullService.Variant.Code !== 'TEAMSAUDIOCODES'
            ) {
                dnsWrapper = (
                    <div className="dns-wrapper">
                        {_.map(self.state.fullService.Domains, function (d) {
                            return <DNSRecords
                                key={d.DomainName}
                                domain={d}
                                showTestLink={self.state.fullService.Variant.ServiceCode === 'teams'}
                                showPorts={self.state.fullService.Variant.ServiceCode === 'teams' && !self.state.fullService.SyncEnabled}
                            />;
                        })}
                    </div>
                );
            }

            let isFormDisabled = false;
            let formTitle = null;
            if (service && service.hasOwnProperty('AllowEditInUI') && service.AllowEditInUI == false) {
                isFormDisabled = true;
                formTitle = 'UI edits have been disabled for this Service';
            }

            return (
                <div className="service-panel">
                    <fieldset disabled={isFormDisabled} title={formTitle}>
                        { service.Variant.ServiceCode === 'teams'
                            ? this.getTeamsAdvancedOptions(isFormDisabled)
                            : null}

                        <MicrosoftManualSteps
                            service={service}
                        />

                        {dnsWrapper}
                        <DefaultButton
                            text={<><i className="fa-solid fa-trash"></i></>} onClick={self.showModal} disabled={isFormDisabled}
                        />

                        <Dialog
                            hidden={self.state.hideDialog}
                            onDismiss={self.hideModal}
                            dialogContentProps={{
                                type: DialogType.normal,
                                title: 'Delete Service',
                                subText: 'This is a destructive action. All service users will be deleted.'
                            }}
                            modalProps={{
                                styles: { main: { maxWidth: 450 } },
                            }}
                        >
                            <DialogFooter>
                                <PrimaryButton onClick={(e) => this.deleteServiceClick(e)} text="Delete Service" />
                                <DefaultButton onClick={self.hideModal} text="Cancel" />
                            </DialogFooter>
                            </Dialog>
                    </fieldset>
                </div>
            );
        } else if (self.props.availableService) {
            return (
                <div className="service-panel">
                    <MicrosoftServiceNew
                        availableService={self.props.availableService}
                        showDocuments={self.props.showDocuments}
                        buildPopupCb={self.buildPopupCb}
                        newServiceSyncStartedCb={self.props.newServiceSyncStartedCb ? self.props.newServiceSyncStartedCb : null}
                    />
                </div>
            );
        } else {
            return null;
        }

    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account,
        baseAccountInfo: account.baseAccountInfo
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        refetchServices: () => dispatch(actions.refetchServices()),
        refreshAccount: () => dispatch(actions.refreshAccount())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MicrosoftServicePanel);
