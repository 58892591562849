import React, { Component } from 'react';

import AdminUserReactTable from './AdminUserReactTable';
import AdminInviteReactTable from './AdminInviteReactTable';
import AdminInviteForm from './AdminInviteForm';
import { connect } from 'react-redux';


class TabAdminUser extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            showInviteSection: true,
            tableRevision: 1,
            hideInviteForm: true,
        }

        this.toggleInviteFormClick = this.toggleInviteFormClick.bind(this);
    }

    hideInviteSection() {
        this.setState({
            showInviteSection: false
        });
    }

    triggerTableUpdate() {
        this.setState(prevState => ({
            tableRevision: prevState.tableRevision + 1
        }));
    }

    toggleInviteFormClick(e) {
        if (e) { e.preventDefault(); }
        this.setState(prevState => ({
            hideInviteForm: !prevState.hideInviteForm
        }));
    }

    render() {
        var inviteTableSection = (
            this.state.showInviteSection === true? (
                <div>
                    <h4>Invitations</h4>
                    <AdminInviteReactTable
                        hideInviteSection={() => this.hideInviteSection()}
                        lastUpdate={this.state.tableRevision}
                        userUpdateTrigger={() => this.triggerTableUpdate()}
                    />
                </div>
            ):('')
        );

        var disableAddButton = true;
        var formWrapperClasses = "alert alert-info user-form-wrapper user-form-wrapper--admin";
        if (this.state.hideInviteForm) {
            formWrapperClasses += " hidden";
            disableAddButton = false;
        }


        return (
            <>
                <div className="alert">
                <button className="btn btn-primary" onClick={this.toggleInviteFormClick} disabled={disableAddButton} >
                    <i className="fa-solid fa-plus"></i> Invite Administrative User
                </button>
                </div>

                <div className={formWrapperClasses}>
                    <p>Use the form below to invite an Administrative User. Once submitted the user will receive an email.
                        This contains an acceptance link that will then create the user account with the pre-selected roles.</p>
                    <AdminInviteForm
                        toggleFormClick={this.toggleInviteFormClick}
                        userUpdateTrigger={() => this.triggerTableUpdate()}
                    />
                </div>

                <h4>Administrative Users</h4>
                <AdminUserReactTable
                    userUpdateTrigger={() => this.triggerTableUpdate()}
                    lastUpdate={this.state.tableRevision}
                />

                {inviteTableSection}
            </>
        );
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(TabAdminUser);
