import React, { Component } from 'react';
import { connect } from 'react-redux';

import { toast } from 'react-toastify';
import { cmsGetAdminUserRoleHistoryPromise } from '../CallMSAPI';
import ExpandingTable from '../ExpandingReactTable';

import dayjs from '../dayjs.js';


class AdminUserRoleHistoryTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // Used by table
            filters: [], 
        }

        this.fetchData = this.fetchData.bind(this);
    }

    fetchData(filterValues, searchText, pageSize, page, sortSettings) {
        var self = this;

        var apiParams = {
            adminUserId: self.props.adminUserId,
            baseAccountId: self.props.baseAccount.Id
        };

        if (pageSize) {
            apiParams['pageSize'] = pageSize;
        }

        if (page) {
            apiParams['currentPage'] = page;
        }

        return cmsGetAdminUserRoleHistoryPromise(apiParams).then(res => {
            return res
        }).catch(err => {
            console.log("error", err)
        });

    }

    render() {
        var self = this;

        return (
            <>
                <br />
                <h4> Admin User Roles Change Log </h4>
                <br />
                <div>
                    <ExpandingTable
                        columns={[
                            {
                                id: 'alteredon',
                                Header: 'Altered On',
                                className: 'text-center',
                                accessor: function (d) {
                                    let t = dayjs.utc(d.AlteredOn);
                                    if (t.isValid()) {
                                        if (t.year() === dayjs().year()) {
                                            return <span title={t.fromNow()}>{t.local().format('MMM D, H:mm:ss')}</span>;
                                        } else {
                                            return <span title={t.fromNow()}>{t.local().format('MMM D YYYY, H:mm:ss')}</span>;
                                        }
                                    } else {
                                        return d.AlteredOn;
                                    }
                                },
                                sortable: false,
                                filterable: false,
                                colWidth: 150
                            },
                            {
                                id: 'modifiedby',
                                Header: 'Modified By',
                                className: 'text-center',
                                accessor: function (d) {
                                    return d.ModifiedBy;
                                },
                                sortable: false,
                                filterable: false,
                            },
                            {
                                id: 'adminroleid',
                                Header: 'Admin Role',
                                className: 'text-center',
                                accessor: function (d) {
                                    return d.AdminRole;
                                },
                                sortable: false,
                                filterable: false,
                            },
                            {
                                id: 'status',
                                Header: 'Status',
                                className: 'text-center',
                                accessor: function (d) {
                                    return d.Status;
                                },
                                sortable: false,
                                filterable: false,
                            }
                        ]
                        }
                        fetchData={self.fetchData}
                        loadingText={'Loading...'}
                        noDataText={"You don't have any Admin User Roles Change History."}
                        showPagination={true}
                        pageSizeOptions={[5]}
                        pageSize={5}
                        className='-striped -highlight'
                    />
                </div>
                <br />
                <br />
            </>
        );
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        baseAccount: account.baseAccount,
        account: account.account
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AdminUserRoleHistoryTable);