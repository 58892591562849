import React, { Component } from 'react';
import { Field, Formik } from 'formik';
import { getErrMsg, cmsGetContractTypes, cmsGetContractCurrencies, cmsGetContractSupplierBrands } from '../CallMSAPI';
import { toast } from 'react-toastify';
import { TeachingBubble } from 'office-ui-fabric-react/lib/TeachingBubble';
import { SubmitButton } from '../FormHelpers';
import dayjs from '../dayjs.js';
import { connect } from 'react-redux';
import { showConsoleLogs } from '../CallMSUIHelpers';

var _ = require('lodash');

class SystemContractForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            contractTypeList: [],
            showFieldInfo: false,
            currencies: null,
            supplierBrands: null
        };
        this.toggleFieldInfo = this.toggleFieldInfo.bind(this);
        this.dismissFieldInfo = this.dismissFieldInfo.bind(this);
    }

    toggleFieldInfo() {
        this.setState(prevState => ({ showFieldInfo: !prevState.showFieldInfo }));
    }
    dismissFieldInfo() {
        this.setState({ showFieldInfo: false });
    }

    UNSAFE_componentWillMount(e) {
        var self = this;
        var p = cmsGetContractTypes(self.props.account.Id);
        p.then(function (result) {
            if (result && result.data) {
                self.setState({
                    contractTypeList: result.data
                })
            }
        }, function (err) {
            toast.error(getErrMsg(err));
        });

        cmsGetContractCurrencies(
            self.props.account.Id,
            function (data) {
                if (data) {
                    self.setState({ currencies: data });
                }
            }, function (err) {
                self.setState({
                    currencies: null
                });
                if(showConsoleLogs()) {
                    console.log("No currencies available: " + err);
                }
            });

        cmsGetContractSupplierBrands(
            self.props.account.Id,
            function (data) {
                if (data) {
                    self.setState({ supplierBrands: data });
                }
            }, function (err) {

                self.setState({
                    supplierBrands: null
                });
                if(showConsoleLogs()) {
                    console.log("No supplierBrands available: " + err);
                }
            });
    }

    getFormDefaults() {
        //MinimumRevenueStartDate field needs to be in the format "YYYY-MM-DD" or it won't be shown by the ui
        var self = this;
        var baseDefaults = {
            accountId: this.props.targetAccount.Id,
            CurrencyCode: this.props.targetContract ? this.props.targetContract.CurrencyCode : '',
            IsPurchaseEnabled: this.props.targetContract ? this.props.targetContract.IsPurchaseEnabled : true,
            ContractTypeId: this.props.targetContract ? this.props.targetContract.ContractTypeId : '',
            BillingReference: this.props.targetContract ? this.props.targetContract.BillingReference : '',
            PONumber: this.props.targetContract ? this.props.targetContract.PONumber : '',
            PaymentTermDays: this.props.targetContract ? this.props.targetContract.PaymentTermDays : '',
            IndirectRevenueShare: this.props.targetContract ? this.props.targetContract.IndirectRevenueShare : '',
            MinimumRevenue: this.props.targetContract ? (this.props.targetContract.MinimumRevenue ? this.props.targetContract.MinimumRevenue : '') : '',
            MinimumRevenueStartDate: this.props.targetContract && this.props.targetContract.MinimumRevenueStartDate ? dayjs(this.props.targetContract.MinimumRevenueStartDate).format("YYYY-MM-DD") : '',
            SupplierBrandId: this.props.targetContract ? (this.props.targetContract.SupplierBrandId ? this.props.targetContract.SupplierBrandId : '') : '',
        };

        var brandValue = '';
        var defaultBrandName = self.props.account.Brand;
        if (self.state.supplierBrands) {
            if (this.props.targetContract && this.props.targetContract.SupplierBrandId) {
                brandValue = this.props.targetContract.SupplierBrandId;
            }
            else {
                var brand = self.state.supplierBrands.filter(x => x.Name == defaultBrandName);
                if (brand.length) {
                    brandValue = self.state.supplierBrands.filter(x => x.Name == defaultBrandName)[0].Id;
                }
                else {
                    if (self.props.targetAccount.Brand) {
                        brand = self.state.supplierBrands.filter(x => x.Name == self.props.targetAccount.Brand);
                        if (brand.length) {
                            brandValue = self.state.supplierBrands.filter(x => x.Name == self.props.targetAccount.Brand)[0].Id;
                        }
                        else {
                            brandValue = "";
                        }
                    }
                    else {
                        brandValue = "";
                    }
                }
                baseDefaults["SupplierBrandId"] = brandValue;
            }
        }

        return baseDefaults;
    }

    render() {
        var self = this;

        var includeIndirectRevenueShare = false;

        if (self.state.contractTypeList && this.props.targetContract) {
            var contract = self.state.contractTypeList.filter(x => (x.Id === self.props.targetContract.ContractTypeId) && x.IsIndirect);
            if (contract.length) {
                includeIndirectRevenueShare = true;
            }
            else {
                includeIndirectRevenueShare = false;
            }
        }

        return (
            <Formik
                // Required so updates from getFormDefaults get propagated down to form
                enableReinitialize={true}
                initialValues={this.getFormDefaults()}
                validate={values => {
                    let errors = {};
                    if (!values['SupplierBrandId']) {
                        errors.SupplierBrandId = 'Supplier Brand is a required field';
                    }
                    return errors;
                }}
                onSubmit={(originalValues, { setSubmitting, setErrors, resetForm }) => {

                    var formik = this;

                    // We make various mods to the data before submission, we
                    // don't want to fiddle the underlying data though.
                    var values = _.cloneDeep(originalValues);

                    /* If there is already a contract saved, the three (changed to one as per story 7036) below fields should be readonly, and the values should never be sent to the api
                     If there is no contract and you are creating one, the values should only be sent to the api if there is a value */
                    if (self.props.targetContract) {
                        if (values.hasOwnProperty('IndirectRevenueShare')) { delete values['IndirectRevenueShare']; }
                    } else {
                        if (values.hasOwnProperty('MinimumRevenue') && values['MinimumRevenue'] == '') { delete values['MinimumRevenue']; }
                        if (values.hasOwnProperty('MinimumRevenueStartDate') && values['MinimumRevenueStartDate'] == '') { delete values['MinimumRevenueStartDate']; }
                        if (values.hasOwnProperty('IndirectRevenueShare') && values['IndirectRevenueShare'] == '') { delete values['IndirectRevenueShare']; }
                    }

                    if (values.IsPurchaseEnabled === false) {
                        if (window.confirm("Are you sure you want Is Purchase Enabled set to false? This will suspend ALL subscriptions!")) {
                            self.props.submitCallback(formik, values, setSubmitting, setErrors, resetForm);
                        } else {
                            setSubmitting(false);
                        }
                    } else {
                        self.props.submitCallback(formik, values, setSubmitting, setErrors, resetForm);
                    }
                }}
            >

                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    setSubmitting,
                    setErrors,
                    resetForm
                    /* and other goodies */
                }) => {
                    let options = [
                        <option key={""} value={""} disabled>-- Select a contract type --</option>
                    ];
                    self.state.contractTypeList.forEach(function (v) {
                        options.push(<option key={v.Id} value={v.Id}>{v.Name}</option>);
                    });
                    var marketplaceType = (
                        <>
                            <Field component="select" name="ContractTypeId" className="form-control">
                                {options}
                            </Field>
                        </>
                    );
                    var indirectRevenueShareField = includeIndirectRevenueShare ? (
                        <>
                            <input name="CurrencyCode" type="text" className="form-control" value={values.IndirectRevenueShare} required={true} onChange={handleChange} onBlur={handleBlur} readOnly />
                            <span className="error-message">{errors.IndirectRevenueShare && touched.IndirectRevenueShare && errors.IndirectRevenueShare}</span>
                        </>
                    ) : null;

                    var indirectRevenueShareLabel = includeIndirectRevenueShare ? 'Indirect Revenue Share' : null;

                    let supOptions = [
                        <option className='text-center' key={""} value={""} disabled>-- Select a Brand --</option>];
                    var brandValue = values["SupplierBrandId"];

                    if (self.state.supplierBrands) {
                        self.state.supplierBrands.forEach(function (v) {
                            supOptions.push(<option key={v.Id} value={v.Id}>{v.Name}</option>);
                        });
                    }

                    let currOptions = [
                        <option key={""} value={""} disabled>-- Select a currency --</option>
                    ];
                    if (self.state.currencies) {
                        self.state.currencies.forEach(function (v) {
                            currOptions.push(<option key={v.Code} value={v.Code}>{v.Name}</option>);
                        });
                    }

                    return (
                        <form className="form user-form" onSubmit={handleSubmit}>
                            <fieldset disabled={isSubmitting}>
                                <h4>Account Contract</h4>
                                <div className="user-form-parts-wrapper w-100">
                                    <div className="form-group px-3">
                                        <label>Currency Code</label>
                                        <Field component="select" name="CurrencyCode" className="form-control" style={{ width: '100%' }}>
                                            {currOptions}
                                        </Field>
                                        <span className="error-message">{errors.CurrencyCode && touched.CurrencyCode && errors.CurrencyCode}</span>
                                    </div>
                                    <div className="form-group px-3">
                                        <label>Billing Reference</label>
                                        <input name="BillingReference" type="text" className="form-control" value={values.BillingReference} onChange={handleChange} onBlur={handleBlur} />
                                        <span className="error-message">{errors.BillingReference && touched.BillingReference && errors.BillingReference}</span>
                                    </div>
                                    <div className="form-group px-3">
                                        <label>PO Number</label>
                                        <input name="PONumber" type="text" className="form-control" value={values.PONumber} onChange={handleChange} onBlur={handleBlur} />
                                        <span className="error-message">{errors.PONumber && touched.PONumber && errors.PONumber}</span>
                                    </div>
                                    <div className="form-group px-3">
                                        <label>Payment Terms (Days)</label>
                                        <input name="PaymentTermDays" type="number" min={0} className="form-control" value={values.PaymentTermDays} onChange={handleChange} onBlur={handleBlur} />
                                        <span className="error-message">{errors.PaymentTermDays && touched.PaymentTermDays && errors.PaymentTermDays}</span>
                                    </div>
                                    <div className="form-group px-3">
                                        <label>
                                            <input type="checkbox"
                                                name="IsPurchaseEnabled"
                                                checked={values.IsPurchaseEnabled}
                                                onChange={handleChange}
                                            />
                                            &nbsp; Is Purchased Enabled?
                                        </label>
                                    </div>
                                    <div className="form-group px-3">
                                        <label>Contract Type</label>
                                        {marketplaceType}
                                    </div>
                                    <div className="form-group px-3">
                                        <label>Minimum Revenue</label>
                                        <input name="MinimumRevenue" type="number" min={0} className="form-control" value={values.MinimumRevenue} onChange={handleChange} onBlur={handleBlur} />
                                        <span className="error-message">{errors.MinimumRevenue && touched.MinimumRevenue && errors.MinimumRevenue}</span>
                                    </div>
                                    {indirectRevenueShareLabel ?
                                        <div className="form-group px-3">
                                            <label>{indirectRevenueShareLabel}</label>
                                            {indirectRevenueShareField}
                                        </div>
                                        : null}
                                    <div className="form-group px-3">
                                        <label>Minimum Revenue Start Date</label> {self.props.targetContract ?
                                            <button className="btn btn-link btn--faux-link" onClick={(e) => { e.preventDefault(); self.toggleFieldInfo(); return false; }}>
                                                <i className="fa-solid fa-question-circle" id={"fieldInfo"}></i>
                                            </button>
                                            : null}

                                        {self.state.showFieldInfo && self.props.targetContract ?
                                            <TeachingBubble
                                                target={'#fieldInfo'}
                                                hasCondensedHeadline={true}
                                                onDismiss={() => self.dismissFieldInfo()}
                                                hasCloseIcon={true}
                                                closeButtonAriaLabel="Close"
                                                headline={"Read Only"}
                                            >
                                                <p>These fields are read only, and the values must be changed in the database.</p>
                                            </TeachingBubble>
                                            : null}
                                        <input name="MinimumRevenueStartDate" type="date" className="form-control" value={values.MinimumRevenueStartDate} onChange={handleChange} onBlur={handleBlur} />
                                        <span className="error-message">{errors.MinimumRevenueStartDate && touched.MinimumRevenueStartDate && errors.MinimumRevenueStartDate}</span>
                                    </div>
                                    <div className="form-group px-3">
                                        <label>Supplier Brand </label>
                                        <Field component="select" name="SupplierBrandId" className="form-control" value={brandValue} >
                                            {supOptions}
                                        </Field>
                                        <span className="error-message">{errors.SupplierBrandId && touched.SupplierBrandId && errors.SupplierBrandId}</span>
                                    </div>
                                </div>

                                <div className="user-form-action-buttons">
                                    <SubmitButton className={"btn btn-primary mb-2 right-action-button"} isSubmitting={isSubmitting}> {self.props.targetContract ? 'Update Contract' : 'New Contract'} </SubmitButton>
                                    <button className="btn right-action-button mb-2 close-form" onClick={self.props.closeCallback}>Close</button>
                                </div>


                                {/* <!-- required due to floating-right action buttons --> */}
                                <div className="clearfix"></div>

                                <hr />
                            </fieldset>
                        </form>
                    )
                }
                }

            </Formik>
        );
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account
    };
}
const mapDispatchToProps = () => {
    return {
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(SystemContractForm);