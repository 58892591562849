import React, { useState, useEffect } from "react";
import AutoSyncRow from "./AutoSyncRow";
import {
  TeachingBubble,
  Toggle,
  DefaultButton,
  Dialog,
  DialogType,
  PrimaryButton,
} from "@fluentui/react";
import { toast } from "react-toastify";
import { cmsDisableAutoSync } from "../../CallMSAPI.js";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index.js";

var _ = require("lodash");

const NextGenSyncRow = (props) => {
  const [isNextGen, setIsNextGen] = useState(
    props.account.IsBetaFeaturesEnabled
      ? props.account.IsBetaFeaturesEnabled.includes("ENABLENEXTGENSYNC")
      : false
  );
  const [showNextGenSyncHelp, setShowNextGenSyncHelp] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const toggleNextGenSyncHelp = () => {
    setShowNextGenSyncHelp(!showNextGenSyncHelp);
  };

  const dismissNextGenSyncHelp = () => {
    setShowNextGenSyncHelp(false);
  };

  const dismissConfirmDialog = (newVal) => {
    setShowConfirmModal(false);
    props.setFieldValue("AutoSyncToggle", newVal);
  };
  const showConfirmDialog = () => {
    setShowConfirmModal(true);
  };

  const modalProps = {
    isBlocking: true,
    className: "allow-overflow-modal",
  };

  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <Toggle
            label={
              <div>
                <label htmlFor="nextGenSyncBtn">Next Gen Teams Sync</label>
                <button
                  id="nextGenSyncBtn"
                  className="btn btn-link btn--faux-link"
                  onClick={(e) => {
                    e.preventDefault();
                    toggleNextGenSyncHelp();
                  }}
                >
                  <i
                    className="fa-solid fa-question-circle"
                    id={"nextGenSyncHelp"}
                  />
                </button>
                {showNextGenSyncHelp && (
                  <TeachingBubble
                    target={"#nextGenSyncHelp"}
                    hasCondensedHeadline={true}
                    onDismiss={dismissNextGenSyncHelp}
                    hasCloseIcon={true}
                    closeButtonAriaLabel="Close"
                  >
                    <>
                      <p>
                        Toggle this on to activate our next generation of the
                        Teams sync process which includes a more efficient sync
                        and proactive error reporting.
                      </p>
                    </>
                  </TeachingBubble>
                )}
              </div>
            }
            defaultChecked={props.values.NextGenSyncToggle}
            checked={props.values.NextGenSyncToggle}
            onText="On"
            offText="Off"
            onChange={(_e, checked) => {
              if (!checked) {
                if (
                  props.fullTeams[0] &&
                  props.fullTeams[0].TeamsSettings.IsAutoSyncEnabled
                ) {
                  return showConfirmDialog();
                } else {
                  props.setFieldValue("NextGenSyncToggle", false);
                }
              }
              props.setFieldValue("NextGenSyncToggle", checked);
            }}
            disabled={props.disabled}
          />
        </div>
        <Dialog
          hidden={!showConfirmModal}
          onDismiss={() => dismissConfirmDialog(false)}
          modalProps={modalProps}
          style={{ minWidth: "80%", overflow: "visible" }}
          dialogContentProps={{
            type: DialogType.normal,
            title: "Confirmation Required",
            subText:
              "This action will switch off Auto Sync. You will have to manually synchronize changes.",
          }}
        >
          <div>
            <PrimaryButton
              onClick={() => {
                let tenantId = _.find(props.fullTeams[0].SyncSettings, {
                  Key: "TenantId",
                });
                let tenantIdVal = tenantId ? tenantId.Value : null;
                cmsDisableAutoSync(
                  props.account.Id,
                  props.fullTeams[0].Id,
                  tenantIdVal
                )
                  .then((res) => {
                    dismissConfirmDialog(false);
                  })
                  .catch((err) => {
                    dismissConfirmDialog(false);
                    toast.error(err.message);
                  });
                props.setFieldValue("NextGenSyncToggle", false);
              }}
              style={{ marginRight: "4px" }}
            >
              Confirm
            </PrimaryButton>
            <DefaultButton
              onClick={() => {
                dismissConfirmDialog(true);
              }}
              style={{ marginLeft: "4px" }}
            >
              Cancel
            </DefaultButton>
          </div>
        </Dialog>
        {props.values.ShowAutoSyncToggle && (
          <AutoSyncRow
            account={props.account}
            values={props.values}
            errors={props.errors}
            touched={props.touched}
            setFieldValue={props.setFieldValue}
            disabled={props.disabled}
          />
        )}
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  const account = state.account;
  const services = state.services;
  return {
    account: account.account,
    fullTeams: services.fullTeams,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    refetchServices: () => dispatch(actions.refetchServices()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NextGenSyncRow);
