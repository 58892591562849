import React, { Component } from 'react';
import ReactTable from 'react-table';
import { toast } from 'react-toastify';
import ExpandingTable from '../../ExpandingReactTable.js';
import { cmsGet, cmsGetPromise } from '../../CallMSAPI.js';

import { Link } from 'react-router-dom'
import { connect } from 'react-redux';

var _ = require('lodash');

class BrandingTemplatesReactTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filters: [],
            searchText: []
        }

        this.getColumns = this.getColumns.bind(this);
        this.fetchData = this.fetchData.bind(this);
    }

    getColumns = () => {
        var self = this;
        var columns = [];

        columns.push(
            {
                id: 'service_name',
                Header: 'Template Name',
                accessor: function (x) {
                    return x.Name
                },
                Filter: ({ filter, onChange }) => self.getServiceVariantsFilters(
                    self.props.serviceVariants,
                    filter,
                    onChange,
                    'Template Name'
                )
            }
        );

        columns.push(
            {
                id: 'service_variant',
                Header: 'Service',
                accessor: function (x) {
                    return x.ServiceVariantName
                },
                sortable: false,
                className: 'col-status-light',
                Filter: ({ filter, onChange }) => self.getServiceVariantsFilters(
                    self.props.serviceVariants,
                    filter,
                    onChange,
                    'Service'
                )
            },
        );

        columns.push(
            {
                id: 'location',
                Header: 'Location',
                accessor: function (x) {
                    return x.Location
                },
                sortable: false,
                filterable: false
            },
        );

        columns.push(
            {
                id: 'template_id',
                accessor: function (x) {
                    return (
                        <Link key='templates' to={`/accounts/${self.props.account.Id}/branding/templates/edit/${x.Id}`}><i className='fa-solid fa-pencil'></i></Link>
                    )
                },
                sortable: false,
                filterable: false,
                colWidth: 50,
            },
        );


        return columns;
    }

    getServiceVariantsFilters = (serviceVariants, filter, onChange, type) => {
        let self = this;
        var opts = _.map(serviceVariants, function (s) { return (<option key={s.Id} value={s.Id}>{s.Name}</option>) });

        if(type === 'Template Name'){
            return (
                <input
                    onChange={event => {
                        let temp =[];
                        temp[type] = event.currentTarget.value;
                        self.setState({filters:  temp});
                    }}
                    style={{ width: "100%" }}
                    value={self.state.filters[type]}
                />
            );
        }

        return (
            <select
                onChange={event => {
                    let temp = [...this.state.filters];
                    temp[type] = event.target.value;
                    self.setState({filters: temp});
                }}
                style={{ width: '100%' }}
                value={self.state.filters && self.state.filters[type] ? self.state.filters[type] : "all"}>
                <option key='default' value=''>All</option>
                {opts}
            </select>
        );
    }

    fetchData(filterValues, searchText, pageSize, page, sortSettings) {
        var self = this;

        var apiParams = {
            accountId: self.props.account.Id,
            objectType: 'pbxtemplates',
            Scope: 'OwnedTemplate',
            ServiceVariantId: null,
            sortBy: 'Name',
            sortDirection: sortSettings && sortSettings.SortDirection ?
            sortSettings.SortDirection : 'Ascending', 
        };

        if (pageSize) {
            apiParams['pageSize'] = pageSize;
        }

        if (page) {
            apiParams['currentPage'] = page;
        }

        if(filterValues){
            if(filterValues['Template Name']) {
                apiParams['searchText'] = filterValues['Template Name'];
            }
            if(filterValues['Service']) {
                apiParams['ServiceVariantId'] = filterValues['Service'];
            }
        }
      
        return cmsGetPromise(apiParams);
    }

    render = () => {
        var self = this;
        var columns = self.getColumns();

        return (
            <>
                <ExpandingTable
                    columns={columns}
                    loadingText={'Loading...'}
                    noDataText={"You don't have any templates yet. Click on one of the add buttons above to get started."}
                    showPagination={true}
                    pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                    className='-striped -highlight'
                    fetchData={self.fetchData}
                    filters={self.state.filters}
                    searchText={self.state.searchText}
                />
            </>
        )
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(BrandingTemplatesReactTable);
