import React, { Component } from 'react';
import { cmsEditAccount, cmsPutAccountPrivateData, getErrMsg, cmsGetServicesPromise, cmsGetFullService } from '../CallMSAPI.js';
import { TeachingBubble } from 'office-ui-fabric-react/lib/TeachingBubble';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import AvailableServicesFormPart from './AvailableServicesFormPart';
import { SubmitButton } from '../FormHelpers';
import { canAccess, isBetaFeaturesEnabledOptions, isValidWord } from '../CallMSUIHelpers.js';
import { connect } from 'react-redux';
import * as actions from '../store/actions/index';
import CountryIdAsyncSelect from '../CountryIdAsyncSelect.js';

var _ = require('lodash');

class EditAccountForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Visual state
            hideForm: true,
            // If you cannot add a new account you cannot edit existing reference fields etc
            isReadonly: !props.hasRole('AccountAdd'),
            showReferenceHelp: false,
            showReference2Help: false,
            showReference3Help: false,
            showExtraRow: props.userUI.showPrivateFields,
            showEditNameHelp: false,
            isPhoneAppDirectRoutingBot: false
        };
        this.submitCallback = this.submitCallback.bind(this);
        this.toggleReferenceHelp = this.toggleReferenceHelp.bind(this);
        this.dismissReferenceHelp = this.dismissReferenceHelp.bind(this);
        this.toggleReference2Help = this.toggleReference2Help.bind(this);
        this.dismissReference2Help = this.dismissReference2Help.bind(this);
        this.toggleReference3Help = this.toggleReference3Help.bind(this);
        this.dismissReference3Help = this.dismissReference3Help.bind(this);
        this.toggleExtraRow = this.toggleExtraRow.bind(this);
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
        this.toggleHelp = this.toggleHelp.bind(this);
        this.dismissHelp = this.dismissHelp.bind(this);
        this.checkIfPhoneAppBot = this.checkIfPhoneAppBot.bind(this);

    }
    componentDidMount() {
        this.checkIfPhoneAppBot();
    }

    checkIfPhoneAppBot() {
        let service = null;
        cmsGetServicesPromise(this.props.targetAccount.Id).then((res) => {
            if (res && res.data && res.data.Results && res.data.Results.length > 0) {
                service = res.data.Results.find(x => x.ServiceSyncModuleCode && x.ServiceSyncModuleCode === 'TEAMS');
                if (service) {
                    return cmsGetFullService(this.props.targetAccount.Id, service.Id);
                }
            }
        }).then((res) => {
            if (res && res.data && res.data.TeamsSettings) {
                this.setState({ isPhoneAppDirectRoutingBot: res.data.TeamsSettings.IsPhoneAppDirectRoutingBot });
            }
        })
    }

    toggleReferenceHelp() {
        this.setState(prevState => ({ showReferenceHelp: !prevState.showReferenceHelp }));
    }

    dismissReferenceHelp() {
        this.setState({ showReferenceHelp: false });
    }

    toggleReference2Help() {
        this.setState(prevState => ({ showReference2Help: !prevState.showReference2Help }));
    }

    dismissReference2Help() {
        this.setState({ showReference2Help: false });
    }

    toggleReference3Help() {
        this.setState(prevState => ({ showReference3Help: !prevState.showReference3Help }));
    }

    dismissReference3Help() {
        this.setState({ showReference3Help: false });
    }

    toggleExtraRow() {
        this.setState(prevState => ({ showExtraRow: !prevState.showExtraRow }), () => {
            this.props.setUserUI({ showPrivateFields: this.state.showExtraRow });
        });
    }

    toggleHelp = (name) => {
        this.setState({ [name]: !this.state[name] });
    }

    dismissHelp = name => {
        this.setState({ [name]: false });
    }

    handleDropdownChange(event, option, selected) {
        if (option.selected) {
            selected.push(option.key)
        }
        else {
            selected = selected.filter(x => x !== option.key)
        }
        return selected
    }

    toCommaSeparatedString = (values) => {
        return _.orderBy(values, x => x, ["asc"]).join(", ")
    }

    getFormDefaults() {
        var baseDefaults = {
            accountId: this.props.targetAccount.Id,
            Name: this.props.targetAccount.Name,
            ExternalId: this.props.targetAccount.ExternalId ? this.props.targetAccount.ExternalId : '',
            PrivateReference: this.props.privateData && this.props.privateData.Reference1 ? this.props.privateData.Reference1 : '',
            PrivateReference2: this.props.privateData && this.props.privateData.Reference2 ? this.props.privateData.Reference2 : '',
            CountryId: this.props.targetAccount.CountryId ? this.props.targetAccount.CountryId : '',
            StateId: this.props.targetAccount.StateId ? this.props.targetAccount.StateId : '',
            AvailableServiceVariants: [],
            AvailableServicesBase: [],
            AvailableServicesTarget: [],
            IsBetaFeaturesEnabled: [],
            AllowEditInUI: this.props.targetAccount.hasOwnProperty('AllowEditInUI') ? this.props.targetAccount.AllowEditInUI : true
        };

        // Default to parent account's country/state
        if (this.props.targetAccount
            && this.props.targetAccount.hasOwnProperty('CountryId')
            && this.props.targetAccount.hasOwnProperty('StateId')
        ) {
            baseDefaults['CountryId'] = this.props.targetAccount.CountryId ? this.props.targetAccount.CountryId : '';
            baseDefaults['StateId'] = this.props.targetAccount.StateId ? this.props.targetAccount.StateId : '';
        }

        if (this.props.targetAccount
            && this.props.targetAccount.hasOwnProperty('IsBetaFeaturesEnabled')
            && this.props.targetAccount.IsBetaFeaturesEnabled
        ) {
            baseDefaults['IsBetaFeaturesEnabled'] = this.props.targetAccount.IsBetaFeaturesEnabled.replace(/ /g,'').split(',');
        }

        this.props.targetAccount.AvailableServices.forEach(function (s) {
            s.Variants.forEach(function (v) {
                baseDefaults[v.Id] = true;
            });
        });

        if (this.props.account && this.props.account.hasOwnProperty('AvailableServices')) {
            _.orderBy(this.props.account.AvailableServices, ['Name']).forEach(function (s) {
                baseDefaults.AvailableServicesBase.push(s)
            });
        }

        // Include existing available service, even if not available on base account
        // e.g. they used to have 1st gen, now just 2nd, continue to show 1st
        if (this.props.targetAccount && this.props.targetAccount.hasOwnProperty('AvailableServices')) {
            // For UI
            _.orderBy(this.props.targetAccount.AvailableServices, ['Name']).forEach(function (s) {
                baseDefaults.AvailableServicesTarget.push(s)
            });
        }

        return baseDefaults;
    }

    submitCallback(formik, values, setSubmitting, setErrors, resetForm) {
        var self = this;
        cmsEditAccount(values, function (data) {
            setSubmitting(false);

            var Reference1 = values['PrivateReference'] ? values['PrivateReference'] : null;
            var Reference2 = values['PrivateReference2'] ? values['PrivateReference2'] : null;
            var data = {
                Reference1: Reference1,
                Reference2: Reference2
            }

            cmsPutAccountPrivateData(self.props.baseAccount.Id, self.props.targetAccount.Id, data, function () {
                // Clear form, close form
                resetForm && resetForm(formik.getFormDefaults());
                toast.success("Account successfully updated");
            });

            // Fire further up chain updates (e.g. table redraw)
            self.props.accountUpdateTrigger && self.props.accountUpdateTrigger(data);
        }, function (error) {
            setSubmitting(false);
            toast.error(error);
        });

    }

    render() {
        var self = this;
        var isBetaFeaturesEnabled =
            canAccess(
                "IsBetaFeaturesEnabled",
                self.props.baseAccount.roles);

        var isSubscriptionFeaturesEnabled =
            canAccess(
                "IsSubSubscriptionFeatures",
                self.props.baseAccount.roles)
            && self.props.targetAccount
            && self.props.targetAccount.hasOwnProperty('Features')
            && self.props.targetAccount.Features.length > 0

        return (
            <Formik
                // Required so updates from getFormDefaults get propagated down to form
                enableReinitialize={true}
                initialValues={this.getFormDefaults()}
                validate={values => {
                    let errors = {};

                    // Ensure PBX username is set
                    if (!values.Name) {
                        errors.Name = 'You must enter an Account Name.';
                    } else if (!isValidWord(values.Name)) {
                        errors.Name = 'You must enter a valid Account Name.';
                    }

                    if (!values.CountryId && !values.StateId) {
                        errors.CountryId = 'You must select a country.';
                    }

                    if (values.ExternalId && !isValidWord(values.ExternalId)) {
                        errors.ExternalId = 'You must enter a valid Reference.';
                    }

                    return errors;
                }}
                onSubmit={(originalValues, { setSubmitting, setErrors, resetForm }) => {
                    var formik = this;
                    // We make various mods to the data before submission, we
                    // don't want to fiddle the underlying data though.
                    var values = _.cloneDeep(originalValues);

                    if (values['StateId']) {
                        values['CountryId'] = '';
                    }

                    if (values.ExternalId === '') {
                        values.ExternalId = null;
                    }
                    if (values.PrivateReference === '') {
                        values.PrivateReference = null;
                    }
                    if (values.PrivateReference2 === '') {
                        values.PrivateReference2 = null;
                    }

                    // Get selected account services into the required order
                    values.AvailableServicesBase.forEach(function (s) {
                        s.Variants.forEach(function (v) {
                            if (values.hasOwnProperty(v.Id)) {
                                if (values[v.Id]) {
                                    values.AvailableServiceVariants.push(v.Id);
                                }
                                delete values[v.Id];
                            }
                        });
                    });
                    delete values.AvailableServicesBase;

                    // Include selected account services that exist for legacy (e.g. base doesn't offer anymore but target account still has them set)
                    values.AvailableServicesTarget.forEach(function (s) {
                        s.Variants.forEach(function (v) {
                            if (values.hasOwnProperty(v.Id)) {
                                if (values[v.Id]) {
                                    values.AvailableServiceVariants.push(v.Id);
                                }
                                delete values[v.Id];
                            }
                        });
                    });

                    delete values.AvailableServicesTarget;

                    if(values.IsBetaFeaturesEnabled){
                        if(!values.IsBetaFeaturesEnabled.includes('ENABLENEXTGENPHONEAPP')){
                            if(values.IsBetaFeaturesEnabled.includes('SINGLESTAGEDIALING')){
                                values.IsBetaFeaturesEnabled =  values.IsBetaFeaturesEnabled.filter(x => x != 'SINGLESTAGEDIALING');
                            }
                        }
                    }

                    values.IsBetaFeaturesEnabled = values.IsBetaFeaturesEnabled.length > 0
                        ? values.IsBetaFeaturesEnabled.toString()
                        : "";

                    self.submitCallback(formik, values, setSubmitting, setErrors, resetForm);
                }}
            >

                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    setSubmitting,
                    setErrors,
                    resetForm
                    /* and other goodies */
                }) => {

                    let isFormDisabled = isSubmitting;
                    let formTitle = null;
                    if (values.AllowEditInUI == false) {
                        isFormDisabled = true;
                        formTitle = 'UI edits have been disabled for this Account';
                    }
                    return (
                        <form className="form user-form update-domain-form" onSubmit={handleSubmit}>
                            <fieldset className="w-100 form-padding" disabled={isFormDisabled} title={formTitle}>
                                <h4 style={{ width: '100%' }}>
                                    Account Details
                                    <small style={{ float: 'right' }}><em>
                                        <button className="btn btn-link btn--faux-link" onClick={(e) => { e.preventDefault(); self.toggleExtraRow(); return false; }}>{self.state.showExtraRow ? 'hide advanced fields' : 'show advanced fields'}</button>
                                    </em></small>
                                </h4>
                                <div className="user-form-parts-wrapper">
                                    <div className="form-group px-3">
                                        <label>Name</label>
                                        <button className="btn btn-link btn--faux-link" onClick={(e) => { e.preventDefault(); self.toggleHelp("showEditNameHelp"); return false; }}>
                                            <i className="fa-solid fa-question-circle" id={"EditNameHelp"}></i>
                                        </button>
                                        {self.state.showEditNameHelp ?
                                            <TeachingBubble
                                                target={'#EditNameHelp'}
                                                hasCondensedHeadline={true}
                                                onDismiss={() => self.dismissHelp("showEditNameHelp")}
                                                hasCloseIcon={true}
                                                closeButtonAriaLabel="Close"
                                                headline={"Name Help"}
                                            >
                                                <p>The Name field is restricted to alphanumeric characters, punctuation, spaces and the special characters "|" and "+".</p>
                                            </TeachingBubble>
                                            : null}
                                        <input disabled={self.state.isReadonly} name="Name" type="text" className="form-control mb-2 mr-sm-2" value={values.Name} required={true} onChange={handleChange} onBlur={handleBlur} />
                                        <span className="error-message">{errors.Name && touched.Name && errors.Name}</span>
                                    </div>
                                    <div className="form-group px-3">
                                        <label>Reference</label>
                                        <button className="btn btn-link btn--faux-link" onClick={(e) => { e.preventDefault(); self.toggleReferenceHelp(); return false; }}>
                                            <i className="fa-solid fa-question-circle" id={"ReferenceHelp"}></i>
                                        </button>

                                        {self.state.showReferenceHelp ?
                                            <TeachingBubble
                                                target={'#ReferenceHelp'}
                                                hasCondensedHeadline={true}
                                                onDismiss={() => self.dismissReferenceHelp()}
                                                hasCloseIcon={true}
                                                closeButtonAriaLabel="Close"
                                                headline={"Reference Help"}
                                            >
                                                <p>This field is public to accounts above this in the hierarchy.</p>
                                                <p>This field is restricted to alphanumeric characters, punctuation, spaces and the special characters "|" and "+".</p>
                                            </TeachingBubble>
                                            : null}

                                        <input disabled={self.state.isReadonly} name="ExternalId" type="text" className="form-control mb-2 mr-sm-2" value={values.ExternalId} onChange={handleChange} onBlur={handleBlur} />
                                        <span className="error-message">{errors.ExternalId && touched.ExternalId && errors.ExternalId}</span>

                                    </div>
                                    {self.state.showExtraRow ?
                                        <div className="form-group px-3">
                                            <label>Private Ref 1</label>
                                            <button className="btn btn-link btn--faux-link" onClick={(e) => { e.preventDefault(); self.toggleReference2Help(); return false; }}>
                                                <i className="fa-solid fa-question-circle" id={"Reference2Help"}></i>
                                            </button>
                                            {self.state.showReference2Help ?
                                                <TeachingBubble
                                                    target={'#Reference2Help'}
                                                    hasCondensedHeadline={true}
                                                    onDismiss={() => self.dismissReference2Help()}
                                                    hasCloseIcon={true}
                                                    closeButtonAriaLabel="Close"
                                                    headline={"Reference Help"}
                                                >
                                                    <p>This field is only visible to users in the {this.props.baseAccount.Name} account</p>
                                                </TeachingBubble>
                                                : null}
                                            <input name="PrivateReference" type="text" className="form-control mb-2 mr-sm-2" value={values.PrivateReference} onChange={handleChange} onBlur={handleBlur} />
                                            <span className="error-message">{errors.PrivateReference && touched.PrivateReference && errors.PrivateReference}</span>
                                        </div> : null}
                                    {self.state.showExtraRow ?
                                        <div className="form-group px-3">
                                            <label>Private Ref 2</label>
                                            <button className="btn btn-link btn--faux-link" onClick={(e) => { e.preventDefault(); self.toggleReference3Help(); return false; }}>
                                                <i className="fa-solid fa-question-circle" id={"Reference3Help"}></i>
                                            </button>
                                            {self.state.showReference3Help ?
                                                <TeachingBubble
                                                    target={'#Reference3Help'}
                                                    hasCondensedHeadline={true}
                                                    onDismiss={() => self.dismissReference3Help()}
                                                    hasCloseIcon={true}
                                                    closeButtonAriaLabel="Close"
                                                    headline={"Reference Help"}
                                                >
                                                    <p>This field is only visible to users in the {this.props.baseAccount.Name} account</p>
                                                </TeachingBubble>
                                                : null}
                                            <input name="PrivateReference2" type="text" className="form-control mb-2 mr-sm-2" value={values.PrivateReference2} onChange={handleChange} onBlur={handleBlur} />
                                            <span className="error-message">{errors.PrivateReference2 && touched.PrivateReference2 && errors.PrivateReference2}</span>
                                        </div> : null}
                                    {isBetaFeaturesEnabled ?
                                        <div className="form-group form-padding system-owner-action">
                                            <label>Beta Features <i className="fa-solid fa-user-secret" title="System Owner Only"></i></label>
                                            <Dropdown
                                                className="select-standard"
                                                placeholder="Select options"
                                                multiSelect
                                                name="IsBetaFeaturesEnabled"
                                                defaultSelectedKeys={values.IsBetaFeaturesEnabled}
                                                onChange={(e, opt) => setFieldValue('IsBetaFeaturesEnabled',
                                                    this.handleDropdownChange(e, opt, values.IsBetaFeaturesEnabled))}
                                                options={isBetaFeaturesEnabledOptions(!this.state.isPhoneAppDirectRoutingBot)}
                                                styles={{ dropdown: { 'max-width': '100%', 'display': 'grid' } }}
                                            />
                                            <span className="error-message">{errors.IsBetaFeaturesEnabled}</span>
                                        </div>
                                        : null
                                    }
                                    {isSubscriptionFeaturesEnabled ?
                                        <div className="form-group form-padding system-owner-action">
                                            <label>Subscription Features <i className="fa-solid fa-user-secret" title="System Owner Only"></i></label>
                                            <p>{self.toCommaSeparatedString(self.props.targetAccount.Features)}</p>
                                        </div>
                                        : null
                                    }
                                    {canAccess("AccountAddBranding", this.props.baseAccount.roles) &&
                                        <div className="form-group form-padding system-owner-action">
                                            <label>Brand <i className="fa-solid fa-user-secret" title="System Owner Only"></i></label>
                                            <p>{this.props.targetAccount.Brand}</p>
                                        </div>
                                    }
                                    <div className="form-group px-3">
                                        <label>Primary Country</label>
                                        <CountryIdAsyncSelect
                                            countryValue={values.CountryId}
                                            countryName={"CountryId"}
                                            stateValue={values.StateId}
                                            stateName={"StateId"}
                                            onChange={setFieldValue}
                                            disabled={self.state.isReadonly}
                                        />
                                        <span className="error-message">{errors.CountryId}</span>
                                    </div>
                                    <div className="form-group px-3">
                                        <AvailableServicesFormPart
                                            values={values}
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            disabled={self.state.isReadonly}
                                        />
                                    </div>
                                    <div className="form-group px-3"></div>
                                </div>

                                <div className="user-form-action-buttons">
                                    {!self.state.isReadonly
                                        ? <SubmitButton className={"btn btn-primary mb-2 right-action-button"} isSubmitting={isSubmitting}> Save </SubmitButton>
                                        : null
                                    }
                                    <button className="btn right-action-button mb-2 close-form" onClick={self.props.closeCallback}>Close</button>
                                </div>


                                {/* <!-- required due to floating-right action buttons --> */}
                                <div className="clearfix"></div>

                                <hr />
                            </fieldset>
                        </form>
                    )
                }
                }

            </Formik>
        );
    }
}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account,
        userUI: account.userUI,
        baseAccount: account.baseAccount
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        hasRole: (uiPart = '') => dispatch(actions.hasRole(uiPart)),
        setUserUI: (params) => dispatch(actions.setUserUI(params))
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(EditAccountForm);