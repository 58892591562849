import React, { Component } from 'react';
import {
    cmsGetPromise
} from '../CallMSAPI.js';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import ExpandingTable from '../ExpandingReactTable';
import { connect } from 'react-redux';
import { showConsoleLogs } from '../CallMSUIHelpers.js';
import dayjs from '../dayjs.js';

var _ = require('lodash');

class AccountTrials extends Component {

    constructor(props) {
        super(props);
        this.state = {
            subscriptions: null,
            tableRows: [],
            pageCount: 0,
            loading: true,
            page: 0,
        };

        this.fetchData = this.fetchData.bind(this);
    }

    fetchData(filterValues, searchText, pageSize, page, sortSettings) {
        var self = this;

        var apiParams = {
            accountId: this.props.account.Id,
            objectType: 'targetsubscriptions',
            State: 'Active',
            IsTrial: 'true',
            IncludeChildAccounts: 'true',
            SortBy: 'end',
            SortDirection: 'Descending'
        };

        if (pageSize) {
            apiParams['pageSize'] = pageSize;
        }

        if (page) {
            apiParams['currentPage'] = page;
        }

        if(sortSettings) {
            if(sortSettings.Column === 'name' || sortSettings.Column === 'Account') {
                apiParams['SortBy'] = 'TargetAccountName';
            }
            if(sortSettings.Column === 'end' || sortSettings.Column === 'Trial End Date') {
                apiParams['SortBy'] = 'ExpiryDate';
            }
            apiParams['SortDirection'] = sortSettings.SortDirection;
        }

        return cmsGetPromise(apiParams)
        .then(data => {
                let resp = {
                    data: {
                        PageCount: data.data.PageCount,
                        CurrentPage: data.data.CurrentPage,
                        Results: null
                    }
                }
                var allSubs = data.data.Results;

                var tableRows = [];

                allSubs.forEach(entry => {

                    var accountName = null;
                    if (entry.TargetAccountName && entry.TargetAccountId) {
                        accountName = <Link key="account" to={"/portal/" + entry.TargetAccountId + '/users'}>{entry.TargetAccountName}</Link>;
                    }
                    var date = entry.UpgradeDate ? 'Converts on ' + dayjs.utc(entry.UpgradeDate).format("DD MMMM YYYY") : entry.ExpiryDate ? 'Expires on ' + dayjs.utc(entry.ExpiryDate).format("DD MMMM YYYY") : '';

                    var rowData = {
                        account: accountName,
                        plan: entry.Description ? entry.Description : entry.PlanName,
                        quantity: entry.Quantity ? entry.Quantity : '',
                        end: date
                    };
                    tableRows.push(rowData);
                })
                resp.data.Results = tableRows;
                return resp;
            });

    }

    render() {
        var self = this;

        const columns = [{
            id: 'account',
            Header: "Account",
            accessor: "account",
            Cell: row => <div style={{ textAlign: "left" }}>{row.value}</div>,
            sortable: true
        }, {
            id: 'plan',
            Header: "Plan",
            accessor: "plan",
            sortable: false,
            Cell: row => <div style={{ textAlign: "left" }}>{row.value}</div>
        }, {
            id: 'quantity',
            Header: "Quantity",
            accessor: "quantity",
            sortable: false,
            Cell: row => <div style={{ textAlign: "left" }}>{row.value}</div>
        }, {
            id: 'end',
            Header: "Trial End Date",
            accessor: "end",
            Cell: row => <div style={{ textAlign: "left" }}>{row.value}</div>,
            sortable: true
        }] 

        return (
            <ExpandingTable
                columns={columns}
                defaultPageSize={10}
                fetchData={self.fetchData}
                sortBy={{
                    Column: 'end',
                    SortDirection: 'Descending',
                }}
                filters={self.state.filters}
                searchText={self.state.searchText}
                noDataText={"You do not currently have any trials"}
                loading={this.state.loading}
                loadingText={"Loading..."}
                showPagination={true}
                pageSizeOptions={[10, 20, 50]}
                className="-striped -highlight"
            />
        );
    }

}
const mapStateToProps = state => {
    const account = state.account;
    return {
        account: account.account
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AccountTrials);