import React, { Component } from 'react';
import { cmsGetServiceSyncJobPromise, cmsGetServiceSyncJob } from '../../CallMSAPI.js';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm';
import dayjs from '../../dayjs.js';

class SyncJobOutput extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            fullData: null,
            nextGen: true
        }
    }

   componentWillMount() {
        var self = this;
        if(self.props.jobId.indexOf('-') > -1) {
            cmsGetServiceSyncJobPromise(
                self.props.account.Id,
                self.props.jobId
            ).then((data) => {
                self.setState({
                    fullData: data.data
                })
            }).catch((err) => {
                toast.error(`Unable to load full job data: ${err}`);
            })
        } else {
            cmsGetServiceSyncJob(
                self.props.account.Id,
                self.props.jobId,
                function (data) {
                    self.setState({
                        fullData: data,
                        nextGen: false
                    });
                }, function (err) {
                    toast.error(`Unable to load full job data: ${err}`);
                }
            );
        }
}

    justCopiedAction() {
        toast.success("Job output copied to clipboard");
    }

    render() {
        var self = this;
        if (!this.state.fullData) {
            return <pre>Loading...</pre>;
        } else {
            var exceptionOutput = null;
            if (self.props.hasRole('ViewExceptions') && this.state.fullData.ExceptionOutput) {
                exceptionOutput = (
                    <>
                        <div className="py-1 row system-owner-action">
                            <div className="col-md-12 text-right">
                                <i className="fa-solid fa-user-secret" title="System Owner Only" style={{ float: 'left', padding: '8px' }}></i>
                                <CopyToClipboard text={this.state.fullData.ExceptionOutput} onCopy={self.justCopiedAction}>
                                    <button className="btn btn-link btn-xs">
                                        <span>Copy Exception Job Output</span> <i className="fa-solid fa-copy"></i>
                                    </button>
                                </CopyToClipboard>
                            </div>
                        </div>

                        <pre className="code-output-limit">
                            {this.state.fullData.ExceptionOutput}
                        </pre>
                    </>
                );
            }

            var debugOutput = null;
            if (this.state.fullData.CaptureDebug && this.state.fullData.DebugOutput) {
                debugOutput = (
                    <>
                        <div className="py-1 row">
                            <div className="col-md-12 text-right">
                                <CopyToClipboard text={this.state.fullData.DebugOutput} onCopy={self.justCopiedAction}>
                                    <button className="btn btn-link btn-xs">
                                        <span>Copy Debug Job Output</span> <i className="fa-solid fa-copy"></i>
                                    </button>
                                </CopyToClipboard>
                            </div>
                        </div>

                        <pre className="code-output-limit">
                            {this.state.fullData.DebugOutput}
                        </pre>
                    </>
                );
            }

            var advice = null;
            if (this.state.fullData.Advice && this.state.fullData.Advice) {
                advice = (
                    <>
                        <p><strong>Advice: </strong></p>
                        <ReactMarkdown className="sync-markdown-wrapper" remarkPlugins={[[remarkGfm, { singleTilde: false }]]}>
                            {this.state.fullData.Advice}
                        </ReactMarkdown>
                    </>
                );
                if(this.state.nextGen){
                    let uniqueAdvice = [...new Set(this.state.fullData.Advice)];
                    advice = (
                        <>
                            <p><strong>Advice: </strong></p>
                            {uniqueAdvice.map((ad, i) => {
                                return (
                                    <p key={`advice-${i}`}>
                                        <ReactMarkdown className="sync-markdown-wrapper" remarkPlugins={[[remarkGfm, { singleTilde: false }]]}>
                                            {ad}
                                        </ReactMarkdown>
                                    </p>
                                )
                            })}
                        </>
                    );
                }
            }

            let output = this.state.fullData.Output;
            if(this.state.nextGen && output) {
                output = [];
                if(this.state.fullData.Output.Sections) {
                    this.state.fullData.Output.Sections.map((line, i) => {
                    
                        output.push(
                            <span key={`outputsection-${i}`}> == {line.Name} == </span>
                        )
                        output.push(<br/>)
                        line.Output.map((oLine, j) => {
                            let timeStamp = dayjs.utc(oLine.TimeStamp).local().format('<HH:mm:ss>')
                            output.push(
                                <span key={`outputsection-${i}-line-${j}`}>{timeStamp} {oLine.Output}</span>
                            )
                            output.push(<br />)
                        })
                    })
                }
            }

            return (
                <>
                    <div className="py-1 row">
                        <div className="col-md-6">
                            <p><strong>Sync Job Id: </strong>{this.state.fullData.Id}</p>
                        </div>
                        <div className="col-md-6 text-right">
                            <CopyToClipboard text={output} onCopy={self.justCopiedAction}>
                                <button className="btn btn-link btn-xs">
                                    <span>Copy Job Output</span> <i className="fa-solid fa-copy"></i>
                                </button>
                            </CopyToClipboard>
                        </div>
                    </div>
                    <div className="py-1 row">
                        <div className="col-md-12">
                            {advice}
                        </div>
                    </div>

                    <hr />
                    <p><strong>Output:</strong></p>
                    <pre className="code-output-limit">
                        {output}
                    </pre>

                    {debugOutput}

                    {exceptionOutput}
                </>
            );
        }
    }
}
export default connect(state => ({
    account: state.account.account
}), dispatch => ({
    hasRole: (uiPart = '') => dispatch(actions.hasRole(uiPart))
}))(SyncJobOutput);
