import React, { Component } from 'react';
import { Field, FieldArray, ErrorMessage } from 'formik';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import { TeachingBubble } from 'office-ui-fabric-react/lib/TeachingBubble';
import Select, { components } from 'react-select';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import {
    cmsGetCountriesPromise,
    cmsGetStatePromise,
    getErrMsg,
    cmsSetPresenceConsentedOn,
    cmsSetPresenceFailedOn,
    cmsGetSyncModuleDefaultSettings,
    cmsTogglePresence
} from '../CallMSAPI';
import { openPopupWindow } from '../ExternalAuthProvider.js';
import FieldWithDisposition from "./FieldWithDisposition";
import { Modal } from 'office-ui-fabric-react/lib/Modal';
import ActionHeader from '../ActionHeader';
import { Icon } from 'office-ui-fabric-react/lib/Icon';
import { getConsentForPresence } from '../MSALAuthProvider';
import { Slider } from '@fluentui/react';

import { toast } from 'react-toastify';
import { Toggle } from 'office-ui-fabric-react';
import { DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { AzureADGroupSelectWrapper } from '../components/service/AzureADGroupWrapperSelect';
import dayjs from '../dayjs.js';
import { isSystemOwner } from '../CallMSUIHelpers.js';
import { useQuery, ReactQueryCacheProvider } from 'react-query';
import { cmsGetFullService, cmsGetPBXTemplatePromise } from '../CallMSAPI';

var _ = require('lodash');
export class TrunkNumberRanges extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            showHelp: false,
            showBulkImport: false,
            textArea: null
        };
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.showBulkImport = this.showBulkImport.bind(this);
        this.hideBulkImport = this.hideBulkImport.bind(this);
        this.toggleHelp = this.toggleHelp.bind(this);
        this.dismissHelp = this.dismissHelp.bind(this);
        this.toggleImportHelp = this.toggleImportHelp.bind(this);
        this.dismissImportHelp = this.dismissImportHelp.bind(this);
        this.parseLines = this.parseLines.bind(this);
    }

    showModal() {
        this.setState({ showModal: true });
    }

    hideModal() {
        this.setState({ showModal: false });
    }

    showBulkImport() {
        this.setState({ showBulkImport: true });
    }

    hideBulkImport() {
        var self = this;
        this.setState({ showBulkImport: false }, function () {
            self.props.setFieldValue('NumberRangesBulkImport', null);
        });
    }

    toggleHelp() {
        this.setState(prevState => ({ showHelp: !prevState.showHelp }));
    }

    dismissHelp() {
        this.setState({ showHelp: false });
    }

    toggleImportHelp() {
        this.setState(prevState => ({ showImportHelp: !prevState.showImportHelp }));
    }

    dismissImportHelp() {
        this.setState({ showImportHelp: false });
    }

    parseLines(value) {
        if (value) {
            return value.replace(/(\\n)/g, "\n");
        } else {
            return null;
        }
    }

    formatBulkImport() {
        var self = this;
        var ranges = self.props.values.NumberRangesBulkImport.split('\n');
        var existingRanges = self.props.values.NumberRanges;
        ranges.forEach(function (range) {
            var start = null;
            var end = null;

            var individualNumbers = range.split(',');
            if (individualNumbers[0]) {
                start = individualNumbers[0].replace(' ', '');
            }
            if (individualNumbers[1]) {
                end = individualNumbers[1].replace(' ', '');
            }
            if (start && !end) {
                end = start;
            }
            existingRanges.push({ Start: start, End: end });
        });
        if (ranges) {
            var notBlank = existingRanges.filter(x => x.Start != '' && x.End != '');
            self.props.setFieldValue('NumberRanges', notBlank);
        }
        self.hideBulkImport();
    }

    render() {
        var self = this;
        var values = self.props.values;
        var errors = self.props.errors;
        var touched = self.props.touched;

        var country = this.props.fullCountry ? this.props.fullCountry : '';
        var state = this.props.state ? this.props.state : '';

        var defaultPlaceholder = '';
        if (country
        ) {
            defaultPlaceholder = '+' + country.DialPrefix;
        }

        var rangeDescriptionText = '';
        var isAboveThree = '';
        var nonEmptyNumberRanges = self.props.values.NumberRanges.filter(x => x.Start != null && x.Start != '' && x.End != null && x.End != '');

        /* If there are 3 or fewer ranges present, show a short description
         * summary of the ranges that exist below the button
         * to add a Number Range/open the Number ranges dialog  */

        if (nonEmptyNumberRanges.length <= 3) {
            for (var i = 0; i < nonEmptyNumberRanges.length; i++) {
                if (nonEmptyNumberRanges.Start && nonEmptyNumberRanges.End) {
                    if (nonEmptyNumberRanges.Start == nonEmptyNumberRanges.End) {
                        rangeDescriptionText += nonEmptyNumberRanges.Start;
                    } else {
                        rangeDescriptionText += nonEmptyNumberRanges.Start + ' to ' + nonEmptyNumberRanges.End;
                    }
                    if (i + 1 != self.props.values.NumberRanges.length) {
                        rangeDescriptionText += ', ';
                    }
                }
            }
            isAboveThree = <div style={{ marginTop: '5px' }}>{rangeDescriptionText}</div>;
        }

        var bulkImportLabel = (
            <label style={{ marginRight: '10px' }}>Insert Ranges below
                <button className="btn btn-link btn--faux-link" onClick={(e) => { e.preventDefault(); self.toggleImportHelp(); return false; }}>
                    <i className="fa-solid fa-question-circle" id="RangesImportHelp"></i>
                </button>
                {self.state.showImportHelp ?
                    <TeachingBubble
                        target={'#RangesImportHelp'}
                        hasCondensedHeadline={true}
                        onDismiss={() => self.dismissImportHelp()}
                        hasCloseIcon={true}
                        closeButtonAriaLabel="Close"
                        headline={"Trunk Number Import"}
                    >
                        <p>Each new line represents a Trunk Number Range.</p>
                        <p>Separate the Start and End of the Range with a comma.</p>
                        <p>If you do not insert a comma, it is assumed that the
                            Range is one number long.</p>

                    </TeachingBubble>
                    : null
                }
            </label>
            );

        return (
            <>
                <div className="form-group">
                    <label style={{ marginRight: '10px' }}>Trunk Number Ranges
                        <button className="btn btn-link btn--faux-link btn-sbc-help" onClick={(e) => { e.preventDefault(); self.toggleHelp(); return false; }}>
                                <i className="fa-solid fa-question-circle" id="NumberRangesHelp"></i>
                        </button>
                        {self.state.showHelp ?
                            <TeachingBubble
                                target={'#NumberRangesHelp'}
                                hasCondensedHeadline={true}
                                onDismiss={() => self.dismissHelp()}
                                hasCloseIcon={true}
                                closeButtonAriaLabel="Close"
                                headline={"Trunk Number Ranges"}
                            >
                                <p>Trunk number ranges are issued by your SIP Trunk provider.
                                Enter the ranges here in E164 format (starting with a '+', no leading zero),
                                then the country number and without spaces or other separators.
                                Ranges are inclusive of start number and end number.</p>
                                <p>Several trunk number ranges can be entered, also individual numbers as single ranges.</p>

                            </TeachingBubble>
                            : null
                        }
                    </label>
                    <div>
                        <button type="button" className="btn btn-primary" onClick={self.showModal} disabled={self.state.showModal || values.IsUIDisabled} style={{ marginRight: '10px' }}>
                            <i className="fa-solid fa-plus"></i> Add/Edit Number
                        </button>
                        <span>{self.props.values.NumberRanges.filter(x => x.Start != null && x.Start != '' && x.End != null && x.End != '').length} {self.props.values.NumberRanges.filter(x => x.Start != null && x.Start != '' && x.End != null && x.End != '').length === 1 ? 'Trunk Number Range' : 'Trunk Number Ranges'}</span>
                        <div style={{ width: '100%' }}>
                            <div className="btn btn-link btn--faux-link" style={{ padding: '5px' }} disabled={values.IsUIDisabled} onClick={() => { if (!values.IsUIDisabled) { self.setState({ showBulkImport: true }) } }}>Bulk Add Number Ranges</div>
                        </div>
                    </div>
                    {isAboveThree}
                    {errors.NumberRanges && errors.NumberRanges.length > 0 && touched.NumberRanges && touched.NumberRanges.length > 0 ?
                        <div className="error-message">
                            {errors.NumberRanges.join(",")}
                        </div>
                        : null}
                </div>
                <Modal
                    isOpen={self.state.showModal}
                    onDismiss={self.hideModal}
                    isBlocking={false}
                    style={{ minWidth: '80%' }}
                >
                    <>
                        <div className="ranges-table-wrapper">
                            <ActionHeader headerText="Trunk Number Ranges" smallHeader={true}>
                                <button className="btn btn-default" onClick={function () {
                                    var notBlank = self.props.values.NumberRanges.filter(x => x.hasOwnProperty('Id'));
                                    if (notBlank.length === 0) {
                                        notBlank.push({ Start: '', End: '' });
                                    }
                                    self.props.setFieldValue('NumberRanges', notBlank);
                                    self.hideModal();
                                }}>
                                    <Icon iconName="Cancel" className="fa-lg" />
                                </button>
                            </ActionHeader>
                            <div>
                                <FieldArray name="NumberRanges" render={({ insert, remove, push }) => (

                <div>
                    <table className="table form-number-range-wrapper">
                        <tbody>
                            {values.NumberRanges && values.NumberRanges.length > 0 &&
                                values.NumberRanges.map(
                                    function (range, index) {
                                        var removeButton = (
                                            <div className="col-md-2">
                                                <button type="button" className="btn btn-default btn--remove-domain-inline" onClick={() => remove(index)}>
                                                    <i className="fa-solid fa-trash"></i>
                                                </button>
                                            </div>
                                        );
                                        var isReadonly = values.NumberRanges[index] && values.NumberRanges[index].hasOwnProperty('Id') ? true : false;
                                        return (
                                            <>
                                            <tr className="row" key={index}>
                                                    <th><p style={{ margin: '5px 0' }}>Range Start</p></th>
                                                <td>
                                                    <Field className="form-control" placeholder={defaultPlaceholder} name={`NumberRanges.${index}.Start`} type="text" readOnly={isReadonly}/>
                                                </td>
                                                    <th><p style={{ margin: '5px 0' }}>Range End</p></th>
                                                <td>
                                                    <Field className="form-control" placeholder={defaultPlaceholder} name={`NumberRanges.${index}.End`} type="text" readOnly={isReadonly}/>

                                                </td>
                                                <td>
                                                    {range && range.RangeSize ? <em>{range.NumLinkedUsers} of {range.RangeSize} used</em> : null}
                                                </td>
                                                <td>
                                                    {removeButton}
                                                </td>
                                            </tr>
                                            {errors.NumberRanges && errors.NumberRanges[index] && touched.NumberRanges && touched.NumberRanges[index] &&
                                                (
                                                    <tr className="row row--error" key={"error-" + index}>
                                                        <td colSpan={6}>
                                                            <div className="error-message">
                                                                {errors.NumberRanges[index]}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                            </>
                                        );
                                    }
                                )
                            }
                        </tbody>
                    </table>

                                        <button type="button" className="btn btn-default" onClick={() => push({ Start: '', End: '' })}>
                                            <i className="fa-solid fa-plus"></i> Add Additional Range
                                        </button>
                                        <button type="button" className="btn btn-default pull-right" onClick={function () {
                                            var notBlank = self.props.values.NumberRanges.filter(x => x.Start != '' && x.End != '');
                                            self.props.setFieldValue('NumberRanges', notBlank);
                                            self.hideModal();
                                        }} style={{ marginLeft: '10px' }} disabled={!self.state.showModal}>Ok</button>
                                        <button type="button" className="btn btn-default pull-right" onClick={function () {
                                            var notBlank = self.props.values.NumberRanges.filter(x => x.hasOwnProperty('Id'));
                                            if (notBlank.length === 0) {
                                                notBlank.push({ Start: '', End: '' });
                                            }
                                            self.props.setFieldValue('NumberRanges', notBlank);
                                            self.hideModal();
                                        }} style={{ marginLeft: '10px' }} disabled={!self.state.showModal}>Cancel</button>
                                    </div>
                                )} />
                            </div>
                        </div>
                    </>
                </Modal>
                <Modal
                    isOpen={self.state.showBulkImport}
                    onDismiss={self.hideBulkImport}
                    isBlocking={true}
                    style={{ minWidth: '80%' }}
                >
                    <div className="ranges-table-wrapper">
                        <ActionHeader headerText="Trunk Number Ranges" smallHeader={true}>
                            <button className="btn btn-default" style={{ marginLeft: '10px' }} onClick={function () {
                                self.hideBulkImport();
                            }}>
                                <Icon iconName="Cancel" className="fa-lg"/>
                            </button>
                        </ActionHeader>
                        {errors.NumberRangesBulkImport && errors.NumberRangesBulkImport.length > 0 ?
                            <div className="error-message">
                                {errors.NumberRangesBulkImport.join(",")}
                            </div>
                            : null}
                        <TextField multiline rows={10} resizable={true} label={bulkImportLabel} value={self.parseLines(values.NumberRangesBulkImport)} onChange={(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newText: string) => {
                            self.props.setFieldValue('NumberRangesBulkImport', newText);
                        }} />
                        <DialogFooter>
                            <PrimaryButton onClick={() => { self.formatBulkImport(); }} disabled={ errors.NumberRangesBulkImport && errors.NumberRangesBulkImport.length > 0 ? true: false } text="OK" />
                            <DefaultButton onClick={() => { self.hideBulkImport(); }} text="Cancel" />
                        </DialogFooter>
                    </div>
                </Modal>
            </>
        );
    }
}

export class ServiceNumbers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            showHelp: false,
        };
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
    }

    showModal() {
        this.setState({ showModal: true });
    }

    hideModal() {
        this.setState({ showModal: false });
    }

    toggleHelp() {
        this.setState(prevState => ({ showHelp: !prevState.showHelp }));
    }

    dismissHelp() {
        this.setState({ showHelp: false });
    }

    render() {
        var self = this;
        var values = self.props.values;
        var errors = self.props.errors;
        var touched = self.props.touched;

        var country = this.props.fullCountry ? this.props.fullCountry : '';
        var state = this.props.state ? this.props.state : '';

        var defaultPlaceholder = '';
        if (country
        ) {
            defaultPlaceholder = '+' + country.DialPrefix;
        }

        var serviceNumbersText  = '';
        var isAboveThree = '';
        var serviceNumbersNotEmpty = values.ServiceNumbers.filter(x => x.PhoneNumber != null && x.PhoneNumber != '');

        /* If there are 3 or fewer service numbers present, show a short description
         * summary of the service numbers that exist below the button
         * to add a service number/open the service numbers dialog      */

        if (serviceNumbersNotEmpty.length <= 3) {
            for (var i = 0; i < serviceNumbersNotEmpty.length; i++) {
                if (serviceNumbersNotEmpty[i].DisplayName && serviceNumbersNotEmpty[i].PhoneNumber) {
                    serviceNumbersText  += serviceNumbersNotEmpty[i].PhoneNumber + ' (' + serviceNumbersNotEmpty[i].DisplayName + ')';
                }
                else if (!serviceNumbersNotEmpty[i].DisplayName && serviceNumbersNotEmpty[i].PhoneNumber) {
                    serviceNumbersText  += serviceNumbersNotEmpty[i].PhoneNumber;
                }
                if (i + 1 != serviceNumbersNotEmpty.length) {
                    serviceNumbersText  += ', ';
                }
            }
            isAboveThree = <div style={{ marginTop: '5px' }}>{serviceNumbersText }</div>;
        }

        return (
            <>
                <div className= "form-group">
                    <label style={{ marginRight: '10px' }}>Service Numbers
                        <button className="btn btn-link btn--faux-link btn-sbc-help" onClick={(e) => { e.preventDefault(); self.toggleHelp(); return false; }}>
                            <i className="fa-solid fa-question-circle" id="ServiceNumbersHelp"></i>
                        </button>
                        {self.state.showHelp ?
                            <TeachingBubble
                                target={'#ServiceNumbersHelp'}
                                hasCondensedHeadline={true}
                                onDismiss={() => self.dismissHelp()}
                                hasCloseIcon={true}
                                closeButtonAriaLabel="Close"
                                headline={"Service Numbers"}
                            >
                                <p>The From Header Type allows service numbers to be used.Enter Service Numbers here in E164 format (starting with a '+', no leading zero),
                                then the country number and without spaces or other separators.</p>
                                <p>Several Service Numbers can be entered.</p>
                            </TeachingBubble>
                            : null
                        }
                    </label>
                    <div>
                        <button type="button" className="btn btn-primary" onClick={self.showModal} disabled={self.state.showModal || values.IsUIDisabled} style={{ marginRight: '10px' }}>
                            <i className="fa-solid fa-plus"></i> Add/Edit Number
                        </button>
                        <span> {values.ServiceNumbers.filter(x => x.PhoneNumber != null && x.PhoneNumber != '').length} {values.ServiceNumbers.filter(x => x.PhoneNumber != null && x.PhoneNumber != '').length === 1 ? 'Service Number' : 'Service Numbers'}</span>
                    </div>
                    {isAboveThree}
                    {errors.ServiceNumbers && errors.ServiceNumbers.length > 0 && touched.ServiceNumbers && touched.ServiceNumbers.length > 0 ?
                        <div className="error-message">
                            {errors.ServiceNumbers.join(",")}
                        </div>
                        : null}
                </div>
                <Modal
                    isOpen={self.state.showModal}
                    onDismiss={self.hideModal}
                    isBlocking={false}
                    style={{minWidth:'80%'}}
                >
                    <>
                        <div className="ranges-table-wrapper">
                            <ActionHeader headerText="Service Numbers" smallHeader={true}>
                                <button className="btn btn-default" onClick={function () {
                                    var notBlankEntries = self.props.values.ServiceNumbers.filter(x => x.hasOwnProperty('Id'));
                                    if (notBlankEntries.length === 0) {
                                        notBlankEntries.push({ DisplayName: '', PhoneNumber: '' });
                                    }
                                    self.props.setFieldValue('ServiceNumbers', notBlankEntries);
                                    self.hideModal();
                                }}>
                                    <Icon iconName="Cancel" className="fa-lg" />
                                </button>
                            </ActionHeader>
                            <p><i>You should only add service numbers which your service provider allows you to use for Caller ID</i></p>
                            <div>
                                <FieldArray name="ServiceNumbers" render={({ insert, remove, push }) => (

                                    <div>
                                        <table className="table form-number-range-wrapper">
                                            <tbody>
                                                {values.ServiceNumbers && values.ServiceNumbers.length > 0 &&
                                                    values.ServiceNumbers.map(
                                                        function (range, index) {
                                                            var removeButton = (
                                                                <div className="col-md-2">
                                                                    <button type="button" className="btn btn-default btn--remove-domain-inline" onClick={() => remove(index)}>
                                                                        <i className="fa-solid fa-trash"></i>
                                                                    </button>
                                                                </div>
                                                            );

                                                            return (
                                                                <tr className="row" key={index}>
                                                                    <th><p style={{ margin: '5px 0' }}>Service Number</p></th>
                                                                    <td>
                                                                        <Field className="form-control" name={`ServiceNumbers.${index}.PhoneNumber`} type="text" placeholder={defaultPlaceholder} />
                                                                        {errors.ServiceNumbers && errors.ServiceNumbers[index] && touched.ServiceNumbers && touched.ServiceNumbers[index] &&
                                                                            (
                                                                                <span className="error-message">
                                                                                    {errors.ServiceNumbers[index]}
                                                                                </span>
                                                                            )
                                                                        }
                                                                    </td>
                                                                    <th><p style={{ margin: '5px 0' }}>Display Name</p></th>
                                                                    <td>
                                                                        <Field className="form-control" name={`ServiceNumbers.${index}.DisplayName`} type="text" />
                                                                    </td>
                                                                    <td>
                                                                        {removeButton}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        }
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                        <button type="button" className="btn btn-default" onClick={() => push({ DisplayName: '', PhoneNumber: ''})} disabled={!self.state.showModal}>
                                            <i className="fa-solid fa-plus"></i> Add Additional Number
                                        </button>
                                        <button type="button" className="btn btn-default pull-right" onClick={function () {
                                            var notBlankEntries = self.props.values.ServiceNumbers.filter(x => x.PhoneNumber != '');
                                            self.props.setFieldValue('ServiceNumbers', notBlankEntries);
                                            self.hideModal();
                                        }} style={{ marginLeft: '10px' }} disabled={!self.state.showModal}>Ok</button>
                                        <button type="button" className="btn btn-default pull-right" onClick={function () {
                                            var notBlankEntries = self.props.values.ServiceNumbers.filter(x => x.hasOwnProperty('Id'));
                                            if (notBlankEntries.length === 0) {
                                                notBlankEntries.push({ DisplayName: '', PhoneNumber: '' });
                                            }
                                            self.props.setFieldValue('ServiceNumbers', notBlankEntries);
                                            self.hideModal();
                                        }} style={{ marginLeft: '10px' }} disabled={!self.state.showModal}>Cancel</button>
                                    </div>
                                )} />
                            </div>
                        </div>
                    </>
                </Modal>
            </>
        );
    }
}

export class AllowedIPs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showHelp: false,
        };
        this.toggleHelp = this.toggleHelp.bind(this);
        this.dismissHelp = this.dismissHelp.bind(this);
    }

    toggleHelp() {
        this.setState(prevState => ({ showHelp: !prevState.showHelp }));
    }

    dismissHelp() {
        this.setState({ showHelp: false });
    }

    render() {
        var self = this;
        var values = self.props.values;
        var errors = self.props.errors;
        var touched = self.props.touched;

        var defaultPlaceholder = "1.2.3.4";

        return (
            <FieldArray name="AllowedIPs" render={({ insert, remove, push }) => (
                <div className="form-group">
                    <label>
                        {self.props.serviceName} Source IPs

                        <button className="btn btn-link btn--faux-link btn-sbc-help" onClick={(e) => { e.preventDefault(); self.toggleHelp(); return false; }}>
                            <i className="fa-solid fa-question-circle" id="TrunkIPHelp"></i>
                        </button>
                        {self.state.showHelp ?
                            <TeachingBubble
                                target={'#TrunkIPHelp'}
                                hasCondensedHeadline={true}
                                onDismiss={() => self.dismissHelp()}
                                hasCloseIcon={true}
                                closeButtonAriaLabel="Close"
                                headline={self.props.serviceName + " IP Addresses"}
                            >
                                <p>If you are using registration this can usually be left blank.
                                These are the IP addresses that
                                your {self.props.serviceName} will
                                originate connections from. Note
                                that if you have entered an IP address in
                                either the domain or proxy fields you will
                                also need to enter that IP address here.
                                </p>
                            </TeachingBubble>
                            : null}

                    </label>
                    <table className="table form-number-range-wrapper">
                        <tbody>
                            {values.AllowedIPs && values.AllowedIPs.length > 0 &&
                                values.AllowedIPs.map(
                                    function (range, index) {
                                        var removeButton = (
                                            <div className="col-md-2">
                                                <button type="button" disabled={values.IsUIDisabled} className="btn btn-default btn--remove-domain-inline" onClick={() => remove(index)}>
                                                    <i className="fa-solid fa-trash"></i>
                                                </button>
                                            </div>
                                        );

                                        return (
                                            <tr className="row" key={index}>
                                                <th>IP Address</th>
                                                <td>
                                                    <Field className="form-control" disabled={values.IsUIDisabled} placeholder={defaultPlaceholder} name={`AllowedIPs.${index}`} type="text" readOnly={self.props.readOnly}/>
                                                    {errors.AllowedIPs && errors.AllowedIPs[index] && touched.AllowedIPs && touched.AllowedIPs[index] &&
                                                        (
                                                            <div className="error-message">
                                                                {errors.AllowedIPs[index]}
                                                            </div>
                                                        )
                                                    }
                                                </td>
                                                <td>
                                                    {removeButton}
                                                </td>
                                            </tr>
                                        );
                                    }
                                )
                            }
                        </tbody>
                    </table>
                    {self.props.readOnly ? null :
                        <button type="button" className="btn btn-primary" disabled={values.IsUIDisabled} onClick={() => push("")}>
                            <i className="fa-solid fa-plus"></i> Add Additional IP
                        </button>
                        }

                </div>
            )} />
        );

        return null;
    }
}

function arrayMove(array, from, to) {
    array = array.slice();
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
    return array;
}

const SortableMultiValue = SortableElement(props => {
    // this prevents the menu from being opened/closed when the user clicks
    // on a value to begin dragging it. ideally, detecting a click (instead of
    // a drag) would still focus the control and toggle the menu, but that
    // requires some magic with refs that are out of scope for this example
    const onMouseDown = e => {
        e.preventDefault();
        e.stopPropagation();
    };
    const innerProps = { onMouseDown };
    return <components.MultiValue {...props} innerProps={innerProps} />;
});
const SortableSelect = SortableContainer(Select);


export function allCodecs() {
    return [
        { label: 'SILK', value: 'SILK' },
        { label: 'G722', value: 'G722' },
        { label: 'G711 PCMU', value: 'PCMU' },
        { label: 'G711 PCMA', value: 'PCMA' },
        { label: 'G729', value: 'G729' },
        { label: 'SIREN', value: 'SIREN' },
        { label: 'Comfort Noise', value: 'CN' },
        { label: 'Redundant Audio Data', value: 'RED' },
    ];
}

export function expandCodecs(arr) {
    return _.map(arr, function (a) { return { label: a, value: a } });
}

export function SortableList(props) {

    var selected = props.values[props.name];
    const onChange = selectedOptions => props.setFieldValue(props.name, selectedOptions);

    const onSortEnd = ({ oldIndex, newIndex }) => {
        const newValue = arrayMove(selected, oldIndex, newIndex);
        props.setFieldValue(props.name, newValue);
    };

    return (
        <SortableSelect
            // react-sortable-hoc props:
            axis="xy"
            onSortEnd={onSortEnd}
            distance={4}
            isDisabled={props.disabled}
            // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
            getHelperDimensions={({ node }) => node.getBoundingClientRect()}
            // react-select props:
            isMulti
            options={props.options}
            value={selected}
            onChange={onChange}
            placeholder={props.placeholder}
            components={{
                MultiValue: SortableMultiValue,
            }}
            closeMenuOnSelect={false}
        />
    );
}

export class E164Form extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
        this.toggleE164 = this.toggleE164.bind(this);
        this.toggleE164Extended = this.toggleE164Extended.bind(this);
        this.toggleE164Help = this.toggleE164Help.bind(this);
        this.dismissE164Help = this.dismissE164Help.bind(this);
        this.formKeysHasSomeVisibleFunc = this.formKeysHasSomeVisibleFunc.bind(this);
    }

    toggleE164Help() {
        this.setState(prevState => ({ showE164Help: !prevState.showE164Help }));
    }

    dismissE164Help() {
        this.setState({ showE164Help: false });
    }

    toggleE164() {
        this.setState(prevState => ({
            showE164: !prevState.showE164
        }))
    }

    toggleE164Extended() {
        this.setState(prevState => ({
            showE164Extended: !prevState.showE164Extended
        }))
    }

    formKeysHasSomeVisibleFunc(FormKeys) {
        var out = false;

        FormKeys.forEach(function (obj) {
            if (obj.State === 'Hide' || obj.Type === 'hidden') {
                // Hidden, so no-op
            } else if (!obj.HiddenOnly) {
                out = true;
            }
        });

        return out;
    }

    render() {
        var self = this;
        var values = self.props.values;
        var errors = self.props.errors;
        var touched = self.props.touched;
        var setFieldValue = self.props.setFieldValue;
        var AllInputs = self.props.AllInputs;
        var isTrunk = self.props.isTrunk;

        var fullCountry = null;
        if (this.props.fullCountry) {
            fullCountry = this.props.fullCountry;
        }


        if (!values['E164Mode'] || values['E164Mode'] !== 'Localized') {
            // We are using a predefined call numbering approach
            return null;
        }

        var FormInputsE164From = _.filter(AllInputs, function (e) { return e.Group && e.Group === 'e164from' });
        var FormInputsE164FromDivs = formKeysToComponentsFunc(values, errors, touched, setFieldValue, FormInputsE164From, self);
        var FormInputsE164To = _.filter(AllInputs, function (e) { return e.Group && e.Group === 'e164to' });
        var FormInputsE164ToDivs = formKeysToComponentsFunc(values, errors, touched, setFieldValue, FormInputsE164To, self);

        // Can check To or From set, they both use same DialingPrefix key
        if (!self.formKeysHasSomeVisibleFunc(FormInputsE164To)) {
            return null;
        }

        var countryCode = '1';
        var hasCountryCode = false;
        if (fullCountry
            && fullCountry.DialPrefix
        ) {
            hasCountryCode = true;
            countryCode = fullCountry.DialPrefix;
        }

        return (
            <>
                <div className="button-header-with-help">
                    <h4 onClick={self.toggleE164}>E164 Number Translation</h4>&nbsp;

                <button className="btn btn-link btn--faux-link" onClick={(e) => { e.preventDefault(); self.toggleE164Help(); return false; }}>
                        <i className="fa-solid fa-question-circle" id={"e164Help"}></i>
                    </button>

                    {self.state.showE164Help ?
                        <TeachingBubble
                            target={'#e164Help'}
                            hasCondensedHeadline={true}
                            onDismiss={() => self.dismissE164Help()}
                            hasCloseIcon={true}
                            closeButtonAriaLabel="Close"
                            headline={"E164 Number Translation"}
                        >
                            <p>These additional options are only required if your PBX/Trunk does not support E164 dialing.</p>
                            <ul>
                                <li><strong>Outbound Prefixes</strong>: Calls
                            outbound to this trunk. The number called will
                            start in E164 format. If the number matches
                            <em>+{countryCode}</em> those characters are
                            replaced with the National field value (e.g. <em>0</em>).
                            If the number has a different country code only the <em>+</em>
                            is replaced with the International field value (e.g. <em>00</em>).
                            </li>
                                <li><strong>Inbound Prefixes</strong>: Calls
                            inbound from this trunk. The incoming number will
                            be converted to E164 format by removing either the value in the
                            International field and replacing with a <em>+</em>, or removing
                            the value in the National field and replacing with <em>+{countryCode}</em>.
                            </li>
                            </ul>
                        </TeachingBubble>
                        : null}
                </div>

                {hasCountryCode ? <p>This {isTrunk ? "trunk's" : "PBX's"} country is configured
            as {fullCountry ? fullCountry.Name : ''} which has a dialing country code of <em>{countryCode}</em>
                </p> : null}

                <div className="user-form-parts-wrapper">
                    {FormInputsE164ToDivs}
                    {FormInputsE164FromDivs}
                </div>
            </>
        );
    }
}

export function formKeysToComponentsFunc(values, errors, touched, setFieldValue, FormKeys, orig) {
    var self = this;
    var FormInputs = [];
    FormKeys.forEach(function (obj) {
        var k = obj.Id;
        var hasError = '';

        /*
         * Track if we are showing an error state for the field,
         * _except_ if it's an empty/missing password, but the service already has one set
         */
        if (errors[k] && touched[k] && !(obj.Type === 'password' && obj.HasPassword)) {
            hasError = 'error';
        }

        /*
         * AuthUsername special case for 'required'.
         * Never actually mandatory as identifier value is used if none supplied
         */
        if (obj.Id === 'AuthUsername' && obj.State.includes('Mandatory')) {
            obj.State = obj.State.replace('Mandatory', 'Optional');
        }

        obj.IsUIDisabled = values.IsUIDisabled;

        if (obj.State === 'Hide' || obj.Type === 'hidden') {
            FormInputs.push(
                <Field key={k} type="hidden" name={k} value={values[k]} />
            );
        } else if (!obj.HiddenOnly) {
            // Special override to ensure country id key moves around
            if (obj.Id === 'CountryId') { k = makeCountryCompKeyFunc(values.CountryId, values.StateId); }

            var className = '';
            if (obj.Group) {
                className = 'form-group-' + obj.Group;
            }

            if (obj.ExtraClass) {
                className += ' ' + obj.ExtraClass;
            }

            FormInputs.push(
                <div key={k} className={"form-group " + className + " " + hasError}>
                    <FieldWithDisposition
                        visibility={obj.State}
                        formObject={obj}
                        formValues={values}
                        errors={errors}
                        touched={touched}
                        template={(orig && orig.props && orig.props.pbxTemplate) ? orig.props.pbxTemplate : null}
                        setFieldValue={setFieldValue}
                        account={(orig && orig.props && orig.props.account) ? orig.props.account : null}
                    />
                </div>
            );
        }
    });

    return FormInputs;
}

export function makeCountryCompKeyFunc(CountryId, StateId) {
    var out = 'country-key-';
    out += CountryId ? CountryId : 'noCountry'
    out += StateId ? StateId : 'noState'
    return out;
}

export function checkWillRegister(FauxDoReg) {
    if (FauxDoReg === 'StandardRegistration'
        || FauxDoReg === 'GlobalRegistration') {
        return true;
    }
    else {
        return false;
    }
}

export function checkHasCredentials(FauxDoReg) {
    if (FauxDoReg.includes('-WithCreds')) {
        return true;
    }
    else {
        return false;
    }
}

export class CountryHOC extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fullCountry: null,
            dialPrefix: null,
            loading: false,
            stateValue: null
        };
        this.getFullCountry = this.getFullCountry.bind(this);
        this.getState = this.getState.bind(this);
    }

    UNSAFE_componentWillMount() {
        var self = this;

        if (self.state
            && self.state.fullCountry === null
            && self.props.CountryId
            && self.props.accountId
            && self.state.loading === false) {
            this.getFullCountry(self.props.CountryId, self.props.accountId);
        }
        else if (self.state.fullCountry === null
            && self.props.StateId
            && self.props.accountId
            && self.state.loading === false) {
            this.getState(self.props.StateId, self.props.accountId);
        }
    }

    componentDidUpdate(prevProps) {
        var self = this;
        if (self.props.accountId && this.state.loading === false) {
            if (
                (this.state.fullCountry                                         //state.fullCountry exists and not equal to props.countryId
                    && self.props.CountryId
                    && this.state.fullCountry.Id != self.props.CountryId
                    && self.props.CountryId != prevProps.CountryId)
                ||
                (self.state.fullCountry === null                                 //state.fullCountry doesn't exist,  props.countryId  is set
                    && self.props.CountryId)
            ) {
                this.getFullCountry(self.props.CountryId, self.props.accountId);
            }
            else if (
                (self.state.fullCountry
                    && self.props.StateId
                    && this.state.fullCountry.StateId != self.props.StateId
                    && self.props.StateId != prevProps.StateId)
                ||
                (self.state.fullCountry === null                                   //state.fullCountry doesn't exist,  props.stateId  is set
                    && self.props.StateId)
            ) {
                this.getState(self.props.StateId, self.props.accountId);
            }
        }
    }

    getState(stateId, accountId) {
        var self = this;
        self.setState({ loading: true },
            function () {
                cmsGetStatePromise(accountId, { stateId: stateId })
                    .then(function (data) {
                        if (data && data.data && data.data.Results) {
                            var state = data.data.Results[0];
                            self.setState({ stateValue: state }, function () {

                                if (state && state.CountryId) {
                                    if (self.state.fullCountry === null || self.state.fullCountry.Id != state.CountryId) {
                                        var countryId = state.CountryId;
                                        cmsGetCountriesPromise(accountId, { 'countryId': countryId }).then(function (data) {
                                            self.setState({ fullCountry: data.data.Results[0], dialPrefix: data.data.Results[0].DialPrefix, loading: false });
                                        });
                                    }
                                    else {
                                        self.setState({ loading: false });
                                    }
                                }
                                else {
                                    self.setState({ loading: false });
                                }
                            });
                        } else {
                            self.setState({ loading: false });
                        }
                    }).catch(function (error) {
                        self.setState({ loading: false });
                    });
            }
        );
    }

    getFullCountry(id, accountId) {
        var self = this;

        self.setState({ loading: true },
            function () {
                cmsGetCountriesPromise(accountId, { 'countryId': id }).then(function (data) {
                    self.setState({ fullCountry: data.data.Results[0], dialPrefix: data.data.Results[0].DialPrefix, loading: false, stateValue: null  });
                });
            }
        );
    }

    render() {
        var children = Array.isArray(this.props.children)
            ? this.props.children.filter(x => x !== null)
            : this.props.children

        const childrenWithProps = React.Children.map(children, child =>
            React.cloneElement(child, {
                fullCountry: this.state.fullCountry,
                dialPrefix: this.state.dialPrefix,
                loading: this.state.loading,
                stateValue: this.state.stateValue
            })
        );

        return (<>{childrenWithProps}</>);
    }
}

export class ServiceNumberSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultValue: {
                DisplayName: 'Trunk Number',
                Id: 'Trunk Number',
                PhoneNumber: null
            },
            options:[]
        };
        this.loadOptions = this.loadOptions.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    UNSAFE_componentWillMount() {
        var options = this.loadOptions();
        this.setState({
            options: options
        });
    }

    loadOptions() {
        var self = this;
        var options = [];

        if (self.props.service) {
            options = _.cloneDeep(self.props.service.TrunkSettings.ServiceNumbers);
        }

        var isDef = options.find(x => x.Id == 'Default');
        var isAnon = options.find(x => x.Id == 'Anonymous');

        if (!isDef && !isAnon) {
            options.unshift(
                {
                    DisplayName: 'Trunk Number',
                    Id: null,
                    PhoneNumber: null
                },
                {
                    DisplayName: 'Anonymous',
                    Id: 'JoMQ49NK',
                    PhoneNumber: null
                });
        }

        for (var i = 0; i < options.length; i++) {
            if (!options[i].label) {
                if (options[i].DisplayName) {
                    options[i].label = options[i].PhoneNumber + " (" + options[i].DisplayName + ")";
                } else {
                    options[i].label = options[i].PhoneNumber;
                }
            }
        }

        if (self.props.value) {
            var defVal = options.find(x => x.Id === self.props.value);
            if (defVal) {
                this.setState({
                    defaultValue: defVal
                });
            }
        }

        return options;
    }

    getOptionLabel(option) {
        var self = this;
        var label = <>{option.PhoneNumber}</>;
        if (!option.PhoneNumber && option.DisplayName) {
            label = <>{option.DisplayName}</>;
        }
        if (option.DisplayName && option.PhoneNumber) {
            label = <>{option.PhoneNumber} <small style={{ color: 'darkgrey' }}> ({option.DisplayName}) </small> </>;
        }
        return (<p style={{ margin: '0' }}>{label}</p>)
    }

    handleChange(option) {
        if (option != this.state.defaultValue) {
            this.setState({
                defaultValue: option
            });
            // Let upstream/formik know about the change
            this.props.onChange(option);
        }
    }

    render() {
        var self = this;
        var options = self.state.options;
        return (
            <Select
                value={this.state.defaultValue}
                getOptionValue={option => option.Id}
                formatOptionLabel={self.getOptionLabel}
                options={options}
                className="select-dropdown"
                name={this.props.name}
                onChange={this.handleChange}
                searchable={true}
            />
        );
    }

}
export function getDistinctArrayValues(value, index, self) {
        return self.indexOf(value) === index;
}

export class LargeTenantModeRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLargeTenantModeHelp: false
        }

        this.toggleLargeTenantModeHelp = this.toggleLargeTenantModeHelp.bind(this);
        this.dismissLargeTenantModeHelp = this.dismissLargeTenantModeHelp.bind(this);
        this.flipLargeTenantToggle = this.flipLargeTenantToggle.bind(this);
    }

    toggleLargeTenantModeHelp() {
        this.setState(prevState => ({ showLargeTenantModeHelp: !prevState.showLargeTenantModeHelp }));
    }

    dismissLargeTenantModeHelp() {
        this.setState({ showLargeTenantModeHelp: false });
    }

    render() {
        var self = this;

        /**
         * Message for users that have the legacy tenant saved still
         **/
        var legacySyncGroupInfo = null;
        if (self.props.fullService) {
            var syncGroup = _.find(self.props.fullService.SyncSettings, { Key: 'UserFilterGroupName' });
            if (syncGroup && syncGroup.Value) {
                legacySyncGroupInfo = (
                    <p className="text-muted"><small>
                        Group: <em>{syncGroup.Value}</em>.&nbsp;
                        This will migrate to Large Tenant Mode during the next sync.
                    </small></p>
                );
            }
        }

        return (
            <div className="row form-padding">
                <div className="form-group">
                    <Toggle
                        label={
                            (<div>
                                <label>Large Tenant Mode</label>
                                <button className="btn btn-link btn--faux-link btn-link--xs" onClick={(e) => { e.preventDefault(); self.toggleLargeTenantModeHelp() }}>
                                    <i className="fa-solid fa-question-circle" id={"large-tenant-mode-help"}></i>
                                </button>
                                {self.state.showLargeTenantModeHelp ?
                                    <TeachingBubble
                                        target={'#large-tenant-mode-help'}
                                        hasCondensedHeadline={true}
                                        onDismiss={() => self.dismissLargeTenantModeHelp()}
                                        hasCloseIcon={true}
                                        closeButtonAriaLabel="Close"
                                        headline={'Large Tenant Mode'}
                                    >
                                        <>
                                            <p>This allows only a selected set of Azure AD groups to be used
                                                when configuring the service. The users must have suitable licenses already.
                                                This mode is best for configuring 1000+ users.</p>
                                        </>
                                    </TeachingBubble>
                                    : null}
                            </div>)
                        }
                        defaultChecked={self.props.values.LargeTenantMode}
                        checked={self.props.values.LargeTenantMode}
                        onText="On"
                        offText="Off"
                        disabled={false}
                        onChange={((e, checked) => self.flipLargeTenantToggle(checked))}
                        disabled={self.props.disabled}
                    />
                </div>
                <div className="form-group lar-tnt-mod" >
                    {self.props.values.LargeTenantMode &&
                        <AzureADGroupSelectWrapper
                            isCallingEssentialsEnabled={self.props.isCallingEssentialsEnabled}
                            legacySyncGroupInfo={legacySyncGroupInfo}
                            groupIdListNative={self.props.values.GroupIdListNative}
                            groupIdListCE={self.props.values.GroupIdListCE}
                            service={self.props.fullService}
                            setFieldValue={self.props.setFieldValue} />
                    }
                </div>
            </div>
        );
    }

    flipLargeTenantToggle(checked) {
        var self = this;

        if (checked) {
            // We are turning it on, nice and easy
            self.props.setFieldValue('LargeTenantMode', checked);
            return;
        }

        // If we are turning it off, will we lose groups?
        if (window.confirm("Are you sure? The next sync will pull in all eligible users.")) {
            self.props.setFieldValue('LargeTenantMode', checked);
        }
    }
}

export class PresenceRow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showPresenceHelp: false,
            PBXNames: null
        }

        this.togglePresenceHelp = this.togglePresenceHelp.bind(this);
        this.dismissPresenceHelp = this.dismissPresenceHelp.bind(this);
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
        this.flipPresenceToggle = this.flipPresenceToggle.bind(this);
    }

    togglePresenceHelp() {
        this.setState(prevState => ({ showPresenceHelp: !prevState.showPresenceHelp }));
    }

    dismissPresenceHelp() {
        this.setState({ showPresenceHelp: false });
    }

    handleDropdownChange(event, option, selected) {
        if (option.selected) {
            selected.push(option.key)
        }
        else {
            selected = selected.filter(x => x !== option.key)
        }
        return selected
    }

    flipPresenceToggle(checked) {
        var self = this;
        self.props.setFieldValue('Presence', checked);
    }

    // Currently just checks one, should instead loop and return any failures
    getPresenceConsentStatus() {
        const presenceClientId =
            this.props.fullService !== undefined &&
            this.props.fullService.SyncSettings !== undefined &&
            this.props.fullService.SyncSettings.filter((x) =>
                x.Key.includes("PresenceClientId")
            );

        if (presenceClientId) {
            const presentClientConsent =
                this.props.fullService
                this.props.fullService.TeamsSettings &&
                this.props.fullService.TeamsSettings.Apps &&
                this.props.fullService.TeamsSettings.Apps.filter((x) =>
                    x.AppId === presenceClientId[0].Value
                );

            if (presentClientConsent && presentClientConsent.length > 0)
            {
              if (presentClientConsent[0].ConsentedOn
                && (!presentClientConsent[0].FailedOn
                    || presentClientConsent[0].FailedOn < presentClientConsent[0].ConsentedOn) ) {
                return {
                  success: true,
                  date: presentClientConsent[0].ConsentedOn,
                };
              } else {
                return {
                  success: false,
                  date: presentClientConsent[0].FailedOn,
                };
              }
            }
        }
      }

    getPresenceConsent()
    {
        getConsentForPresenceApps(
          this.props.fullService,
          this.props.fullService.Id,
          this.props.account.Id
        ).then(() =>
        {
            if (this.props.refreshFullService) { this.props.refreshFullService(); }
        });
    }

    render() {
        const presenceConsentStatus = this.getPresenceConsentStatus();
        var self = this;

        var presence = null;
        var numApps = self.props.values.PresenceAppCount;

        var calloutProps = { gapSpace: 0 };
        let label = null;
        let content = null;
        if (presenceConsentStatus && presenceConsentStatus.date) {
            content = "Presence consent" + (presenceConsentStatus.success ? " succeeded at " : " failed at ") + dayjs(presenceConsentStatus.date).format("YYYY-MM-DD HH:mm:ss");
            label = (
                <TooltipHost content={content} calloutProps={calloutProps} >
                    {presenceConsentStatus.success ? <i style={{ marginLeft: '7px' }} className="fa-solid fa-circle-info"></i> : <i style={{ marginLeft: '7px' }} className="fa-solid fa-triangle-exclamation"></i>}
                </TooltipHost>
            );
        }

        if (self.props.values.Presence) {
            presence = (
                <>
                    <div className="row">
                        <button className="btn btn-primary" type="button" style={{margin: '25px 0'}} onClick={() => {
                            if (window.confirm("You will be requested to consent to" + (numApps === 1 ? " authorise 1 app " : " authorizing " + numApps + " apps ") + "to monitor your licensed users.")) {
                                getConsentForPresenceApps(self.props.values, self.props.fullService.Id, self.props.account.Id).then(() => {
                                    cmsTogglePresence(self.props.account.Id, self.props.fullService.Id, 'enable').then(function (res) {
                                        if (res) {
                                            if (self.props.refreshFullService) { self.props.refreshFullService(); }
                                            toast.success('Presence toggled succesfully');
                                        }
                                    }).catch(err => {
                                        toast.error(err.message)
                                    });
                                });
                            }
                        }}>
                            Authorise Presence
                        </button>
                        {label}
                    </div>
                </>
            );
        }

        return (
            <>
                <div className="row" >
                    <div className="col-md-3">
                        <Toggle
                            label={
                                (<div>
                                    <label>Presence</label>
                                    <button className="btn btn-link btn--faux-link" onClick={(e) => { e.preventDefault(); self.togglePresenceHelp() }}>
                                        <i className="fa-solid fa-question-circle" id={"presenceHelp"}></i>
                                    </button>
                                    {self.state.showPresenceHelp ?
                                        <TeachingBubble
                                            target={'#presenceHelp'}
                                            hasCondensedHeadline={true}
                                            onDismiss={() => self.dismissPresenceHelp()}
                                            hasCloseIcon={true}
                                            closeButtonAriaLabel="Close"
                                            headline={'Presence'}
                                        >
                                            <>
                                                <p>Toggling Presence on allows the PBX to mirror the presence of the Teams user.</p>
                                            </>
                                        </TeachingBubble>
                                        : null}
                                </div>)
                            }
                            defaultChecked={self.props.values.Presence}
                            onText="On"
                            offText="Off"
                            onChange={(e, checked) => self.flipPresenceToggle(checked)}
                            disabled={self.props.disabled}
                        />
                    </div>
                    <div className="col-md-9 inb-call-ess" style={{ margin: '10px 0' }}>
                        {presence}
                    </div>
                </div>
            </>
        );
    }
}

export class EnableSyncRow extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        var self = this;
        return (
            <>
                <div className="row" >
                    <div className="col-md-4">
                        <Toggle
                            defaultChecked={self.props.values.SyncEnabled}
                            onText="On"
                            offText="Off"
                            onChange={((e, checked) => {
                                if (!checked) {
                                    if (window.confirm("Are you sure you want to disable sync" + "?")) {
                                        self.props.setFieldValue('SyncEnabled', checked)
                                    }
                                } else {
                                    self.props.setFieldValue('SyncEnabled', checked)
                                }
                            })}
                            disabled={self.props.disabled}
                        />
                    </div>
                </div>
            </>
        );
    }
}

export function getConsentForPresenceApps(values, teamsServiceId, accId) {
    var popup = null;

    return cmsGetSyncModuleDefaultSettings(accId).then(function (res) {
        if (res) {
            let appIds = res.data
                .filter(x => x.Key.includes("PresenceClientId"))
                .map(applicationData => applicationData.Value);

            let tenantId = res.data.find(x => x.Key === "TenantId").Value;

            let needsWarning = values.PresenceAppCount > appIds.length;
            if (needsWarning) {
                toast.info('Not enough apps are present for this operation');
            }

            let appIdsToRegister = appIds.slice(0, values.PresenceAppCount);
            popup = openPopupWindow('about:blank', "Portal Auth");

            let doNextRegistration = (registrationNumber) => {
                return new Promise(resolve => {
                    let applicationId = appIdsToRegister[registrationNumber];
                    return registerApp(applicationId)
                        .then(() => {
                            let nextRegistrationNumber = registrationNumber + 1;
                            if (nextRegistrationNumber < appIdsToRegister.length) {
                                return doNextRegistration(nextRegistrationNumber)
                                    .then(function (completedRegistrationNumber) {
                                        resolve(completedRegistrationNumber);
                                    });
                            } else {
                                popup.close();
                                resolve(registrationNumber);
                            }
                        })
                        .catch(function (err) {
                            throw new Error(err);
                        });
                });
            };

            let registerApp = (applicationId) => {
                return getConsentForPresence(applicationId, tenantId, popup)
                    .then(function (res) {
                        return cmsSetPresenceConsentedOn(accId, teamsServiceId, applicationId, res.code);
                    })
                    .catch(function (err) {
                        return cmsSetPresenceFailedOn(accId, teamsServiceId, applicationId);
                    })
                    .catch(function (err) {
                        throw new Error(err);
                    });
            }

            return doNextRegistration(0);
        } else {
            throw new Error('Unable to enable Presence');
        }
    }).then(function (results) {
        if (results) {
            toast.success("Account successfully updated");
            if (popup) {
                popup.close();
            }
        }
    }).catch(function (err) {
        toast.error(getErrMsg(err.message));
        if (popup) {
            popup.close();
        }
    })
}

export class PhoneNumberField extends Component {

    shouldIncludePrefix(newValue, phoneNumberOpts) {
        if (
            phoneNumberOpts
            && phoneNumberOpts.hasOwnProperty('prefix')
            && (!newValue
                || (
                    // prefix includes '+' and countryCode
                    // either we are showing for first time,
                    // or the split has already happened, post render, number edit (so not country prefix)
                    newValue && (newValue.includes(phoneNumberOpts.prefix) || !newValue.includes('+'))
                )
            )) {
            return true;
        }
        else {
            return false;
        }
    }
    render() {
        var self = this;

        var phoneNumberOpts = {};
        if (this.props.fullCountry
        ) {
            phoneNumberOpts = {
                prefix: "+" + this.props.fullCountry.DialPrefix
            };
        }

        var phoneCountryLabel = null;
        if (this.props.service
            && this.props.service.SyncEnabled
            && this.props.service.Variant
            && this.props.service.Variant.ServiceCode === 'teams'
            && this.props.fullCountry
            && this.props.fullCountry.hasOwnProperty('Name')
        ) {
            phoneCountryLabel = <>({this.props.fullCountry.Name})</>;
        }

        var lockedEdit = null;
        if (self.props.lockedEdit) {
            lockedEdit = self.props.lockedEdit;
        }

        var msg = null;
        if (self.props.showDirMessage) {
            msg = (
                <TooltipHost
                    content="This user is DirSync'd to Azure AD. Make sure the msRTCSip-Line attribute is not set in Active Directory as this can stop us from setting a phone number."
                    id="dirsynctooltip"
                    calloutProps={{ gapSpace: 0 }}
                    styles={{ root: { display: 'inline-block' } }}
                >
                    <p>
                        <span className="hover-span" aria-describedby="dirsynctooltip">
                            <i className="fa-solid fa-circle-info"></i> <em>DirSync'd user</em>
                        </span>
                    </p>
                </TooltipHost>
            );
        }

        return (
            <>
                {self.props.hideLabel ? (self.props.alternateLabel ? <label>{self.props.alternateLabel} {lockedEdit}</label> : null) : <label>Phone Number {phoneCountryLabel} {lockedEdit}</label>}
                <Field component="TextField" className="form-control" name={this.props.name} value={this.props.value}>
                    {({ field, form, meta }) => {

                        var newValue = field.value;

                        if (newValue && !newValue.includes('+') && phoneNumberOpts.hasOwnProperty('prefix')) {
                            newValue = phoneNumberOpts.prefix + newValue;
                            self.props.setFieldValue(self.props.name, newValue);
                        }

                        if (!self.shouldIncludePrefix(newValue, phoneNumberOpts)) {
                            // We have some random country code already, so don't fiddlg the number
                            phoneNumberOpts = {};
                        } else if (phoneNumberOpts.hasOwnProperty('prefix') && newValue) {
                            // Country already on number matches what we expect
                            // Try strip off the prefix if it's already part of the number
                            if (field.name == 'EssentialsPhoneNumber' && !newValue.includes('+')) {
                                newValue = phoneNumberOpts.prefix + newValue;
                                self.props.setFieldValue(self.props.name, newValue);
                            }
                            newValue = newValue.replace(phoneNumberOpts.prefix, "");
                        }

                        var newOnChange = function (e) {
                            var newVal = e.target.value;

                            // On change, always prepend a prefix if available
                            if (self.shouldIncludePrefix(newVal, phoneNumberOpts)) {
                                newVal = phoneNumberOpts.prefix + newVal;
                            }
                            self.props.setFieldValue(self.props.name, newVal);
                        }


                        return <TextField name={field.name} value={newValue} onChange={newOnChange} disabled={self.props.disabled} {...phoneNumberOpts} />

                    }}
                </Field>
                <ErrorMessage component="span" className="error-message" name={this.props.name} />
                {msg}
            </>
        );
    }
}

export function serviceNameExpander(s) {
    if (!s) {
        return;
    }

    var name = s.Name;
    if (s.hasOwnProperty('ServiceSyncModuleCode')) {
        if (s.ServiceSyncModuleCode && s.ServiceSyncModuleCode.startsWith('AUDIOCODESSYSTEM')) {
            name = 'Audiocodes ' + s.Name;
        }
        if (s.ServiceSyncModuleCode === 'RIBBONSYSTEM') {
            name = 'Ribbon ' + s.Name;
        }
    }

    return name;
}

export function ServicesHOC(props) {

    var services = props.services.sort().join('_');
    var queryConfig = getReactQueryConfig();

    const queryKey = "fullServices_" + props.accountId + "_" + services;

    const { isLoading, error, data, isFetching } = useQuery(queryKey,
        () => {
            return fetchService(props.accountId, props.services)
        },
        queryConfig
    );

    if (isLoading) return "Loading...";

    if (error) return "An error has occurred: " + error.message;

    var children = Array.isArray(props.children)
        ? props.children.filter(x => x !== null)
        : props.children

    const childrenWithProps = React.Children.map(children, child =>
        React.cloneElement(child, {
            services: data,
        })
    );

    /* return the following component inside the below return for debug
    <ReactQueryDevtools initialIsOpen />
    */

    return (
        <>
            {childrenWithProps}
        </>
    );

}
export const ServicesQuery = (accountId, services) => {

    var servicesString = services.sort().join('_');
    var queryConfig = getReactQueryConfig();

    const queryKey = "fullServices_" + accountId + "_" + servicesString;

    const { isLoading, error, data, isFetching } = useQuery(queryKey,
        () => {
            return fetchService(accountId, services)
        },
        queryConfig
    );

    if (isLoading) return "Loading...";

    if (error) return "An error has occurred: " + error.message;

    return data;
}
async function fetchService(accountId, services) {
    var servicesArray = [];
    for (var i = 0; i < services.length; i++) {
        servicesArray.push(cmsGetFullService(accountId, services[i]))
    }
    var services = [];
    return await Promise.all(servicesArray).then(async function (res) {
        for (var i = 0; i < res.length; i++) {
            var servicereq = res[i].data;
            let servOb = {
                service: servicereq,
                template: null
            };
            if (servicereq.PBXSettings && servicereq.PBXSettings.AccountPBXTemplateId) {
                let template = await cmsGetPBXTemplatePromise(accountId, servicereq.PBXSettings.AccountPBXTemplateId);
                servOb.template = template.data;
            }
            services.push(servOb);
        }
        return services;
    });
}
function getReactQueryConfig() {
    return {
        enabled: true,
        cacheTime: 900000,
        staleTime: 300000,
        refetchOnMount: false
    }
}
export const getSBCModeOptions = (isTrunk, values) => {

    const SBCModeOptionsIllegal = [
        { label: 'Select...', value: '' },
        { label: 'Standard Registration', value: 'StandardRegistration' },
        { label: 'Global Registration', value: 'GlobalRegistration' },
        { label: 'Single NonRegistration', value: 'SingleNonRegistration' },
        { label: 'Redundant NonRegistration', value: 'RedundantNonRegistration' },
        { label: 'Legacy NonRegistration', value: 'LegacyNonRegistration' }];
    let SBCModeOptions =  (isTrunk ?
        ((String(values.EmergencyAccountServiceId) != "null" && String(values.EmergencyAccountServiceId) != "undefined") || String(values.Emergency) === 'true'
            ?
            [
                { label: 'Select a Value', value: '', disabled: true },
                { label: 'Dynamic Emergency Trunk with Credentials', value: "DynamicEmergencyTrunk-WithCreds" },
                { label: 'Dynamic Emergency Trunk', value: "DynamicEmergencyTrunk" }, // trunk only
            ]
            :
            [
                { label: 'Select a Value', value: '', disabled: true },
                { label: 'Registration', value: "StandardRegistration" },
                { label: 'Static IPs with Credentials', value: "SingleNonRegistration-WithCreds" }, // trunk only
                { label: 'Static IPs', value: "SingleNonRegistration" },
                { label: 'HA Static IPs with Credentials', value: "RedundantNonRegistration-WithCreds" }, // trunk only
                { label: 'HA Static IPs', value: "RedundantNonRegistration" },
            ])
        :
        [
            { label: 'Select a Value', value: '', disabled: true },
            { label: 'Registration', value: "StandardRegistration" },
            // TBC re Name
            { label: 'No Registration', value: "RedundantNonRegistration" }
        ]
    );
    let SBCMode = {
        options: SBCModeOptions,
        isValueValid: true
    }
    let validValue = SBCModeOptions.find(x => x.value === values.SBCMode);
    let illegalVal = SBCModeOptionsIllegal.find(x => x.value == values.SBCMode && !validValue);
    if (illegalVal) {
        SBCMode.isValueValid = false;
        illegalVal['disabled'] = true;
        SBCMode.options.push(illegalVal);
    }
    return SBCMode;
}
export const getTransferModeOptions = (isTrunk, trueTransferEnabled, transferMode, baseAccountRoles, brandName) => {

    var serviceName = 'PBX';
    if (isTrunk) {
        serviceName = 'Trunk';
    }

    var transferModeOps = [
        { label: 'Select a Value', value: '' },
        { label: serviceName + ' handles transfers', value: 'PhoneOnly' },
        { label: 'Teams handles transfers', value: 'None' }
    ];

    var showTrueTranfer = false;
    if (trueTransferEnabled || transferMode === 'All') {
        showTrueTranfer = true;
    }

    if (showTrueTranfer) {
        transferModeOps.push({ label: 'Teams True Transfer', value: 'All' });
    }

    if ((isSystemOwner(baseAccountRoles) && brandName && brandName !="rbbnconnect") 
        || transferMode === "AllCompatiblePBX") {
        transferModeOps.push({ label: 'Teams True Transfer - Compatible PBX', value: 'AllCompatiblePBX' });
    }

    return transferModeOps;
}

export const getFromHeaderTypeTrunkOptions = (emptyLabel = 'Select a Value', showExtended = false) => {
    let fromHeaderTypeList = [
        { label: emptyLabel, value: '' },
        { label: 'Trunk Caller Id', value: "CallerId" },
        { label: 'SIP Identifier', value: "SIPIdentifier" },
        { label: 'Passthrough Caller Id', value: "PassthroughCallerId" },
    ];

    if (showExtended) {
        fromHeaderTypeList = fromHeaderTypeList.concat([
            { label: 'Trunk Caller Id (E164)', value: "CallerId_E164WithPlus" },
            { label: 'Trunk Caller Id (E164 Without Plus)', value: "CallerId_E164WithoutPlus" },
            { label: 'Passthrough Caller Id (E164)', value: "PassthroughCallerId_E164WithPlus" },
            { label: 'Passthrough Caller Id (E164 Without Plus)', value: "PassthroughCallerId_E164WithoutPlus" },
        ]);
    }

    return fromHeaderTypeList;
}

export const getPAIHeaderTypeTrunkOptions = (emptyLabel = 'Select a Value', showExtended = false) => {
    let paiHeaderTypeList = [
        { label: emptyLabel, value: '' },
        { label: 'Not Present', value: "NotPresent" },
        { label: 'Trunk User Number', value: "CallerId" },
        { label: 'SIP Identifier', value: "SIPIdentifier" },
        { label: 'Passthrough Caller Id', value: "PassthroughCallerId" },
    ];

    if (showExtended) {
        paiHeaderTypeList = paiHeaderTypeList.concat([
            { label: 'Trunk User Number (E164)', value: "CallerId_E164WithPlus" },
            { label: 'Trunk User Number (E164 Without Plus)', value: "CallerId_E164WithoutPlus" },
            { label: 'Passthrough Caller Id (E164)', value: "PassthroughCallerId_E164WithPlus" },
            { label: 'Passthrough Caller Id (E164 Without Plus)', value: "PassthroughCallerId_E164WithoutPlus" },
        ]);

    }

    return paiHeaderTypeList;
}