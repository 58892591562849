import React, { Component } from 'react';
import { cmsGetUsersPromise } from '../CallMSAPI.js';
import { getPbxLabel, serviceCodeToIcon, serviceCodeTypeToIcon, canAccess } from '../CallMSUIHelpers.js'
import { showConsoleLogs } from '../CallMSUIHelpers.js';
import ExpandingTable from '../ExpandingReactTable.js';
import CallLight from "./CallLight";
import EditUserForm from './EditUserForm';
import { connect } from 'react-redux';
import UserPBXRegistrationLight from './UserPBXRegistrationLight.js';

var _ = require('lodash');


class UserReactTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // Used by table
            updateTime: new Date().getTime(),
            filters:[],
            searchText:[]
        }

        this.fetchData = this.fetchData.bind(this);
        this.getColumns = this.getColumns.bind(this);
        this.tableFilter = this.tableFilter.bind(this);
    }

    fetchData(filterValues, searchText, pageSize, page, sortSettings) {
        var self = this;
        var apiParams = {
            'SortBy': 'Part2'
        };

        if (pageSize) {
            apiParams['pageSize'] = pageSize;
        }

        if (page) {
            apiParams['currentPage'] = page;
        }

        if(sortSettings) {
            if (sortSettings.Column === 'SIP User') {
                apiParams['SortBy'] = 'Part1';
            }
            apiParams['SortBy'] = sortSettings.Column;
            apiParams['SortDirection'] = sortSettings.SortDirection;
        }

        if(filterValues) {
            let temp = getPbxLabel(self.props.account);
            if(filterValues['Service']){
                apiParams['part2ServiceId'] = filterValues['Service'];
            }
            if(filterValues[temp]){
                apiParams['part1AccountServiceId'] = filterValues[temp];
            }
            if(filterValues['Registration'] && filterValues['Registration'] !== 'all'){
                apiParams['onlyBadRegistrations'] = true;
            }
        }

        if(searchText) {
            if(searchText['part2_user']){
                apiParams['part2Identifier'] = searchText['part2_user'];
            }
            if(searchText['part1_identifier']){
                apiParams['part1Identifier'] = searchText['part1_identifier'];
            }
        }

        if (!apiParams['part1AccountServiceId'] && !apiParams['part2ServiceId']) {
            // We supply the serviceId for PBX to allow the sort to work correctly
            var pbxAccService = _.find(self.props.services, { ServiceVariantCode: 'PBX' });
            if (pbxAccService) {
                apiParams['part1ServiceVariantId'] = pbxAccService.ServiceVariantId;
                apiParams['part1ServiceFilterAppliedToUnmatchedOnly'] = true;
            }
        }

        return cmsGetUsersPromise(self.props.account.Id, apiParams);
    }

    getColumns() {
        var self = this;
        var columns = [];

        var shouldIncludeServiceColumn = self.props.services.filter(x => x.ServiceCode != 'pbx');

        columns.push(
            {
                id: "part2_user",
                Header: "User",
                accessor: function (d) {
                    if (!d.Part2) {
                        return <span className="unattached-span">Unattached</span>
                    } else {
                        if (d.Part2 && d.Part2.hasOwnProperty('Name') && d.Part2.Name) {
                            return d.Part2.Name;
                        } else {
                            return d.Part2.Identifier;
                        }
                    }
                },
                Filter: ({ filter, onChange }) => self.tableFilter(filter, onChange, "part2_user")
            }
        );

        if (shouldIncludeServiceColumn && shouldIncludeServiceColumn.length > 1) {

            columns.push(
                {
                    id: "part2_service",
                    Header: "Service",
                    sortable: false,
                    className: 'text-center',
                    accessor: function (d) {
                        if (d.Part2) {
                            var service = _.find(self.props.services, function (s) { return s.Id === d.Part2.AccountServiceId });
                            if (service) {
                                return serviceCodeToIcon(service.ServiceCode);
                            }
                        }
                    },
                    filterMethod: (filter, row) => {
                        if (filter.value === "all") {
                            return true;
                        }
                        if (filter.value === "true") {
                            return true;
                        }
                        return false;
                    },
                    Filter: ({ filter, onChange }) => self.serviceFilter(
                        _.filter(self.props.services, function (s) { return s.ServiceCode !== 'pbx' }),
                        filter,
                        onChange,
                        true,
                        "Service"
                    )
                }
            );
        }

        columns.push(
            {
                id: "part2_serviceType",
                Header: "Service Type",
                sortable: false,
                filterable: false,
                className: 'text-left',
                accessor: function (d) {
                    if (d.Part2) {
                        return serviceCodeTypeToIcon(d.Part2.ServiceUserPartType, true);
                    }
                },
            }
        );
  
        columns.push(
            {
                id: "part1_identifier",
                Header: "SIP User",
                accessor: function (d) {
                    var service = _.find(self.props.services, function (s) { return s.Id === d.Part1.AccountServiceId && s.ServiceVariantCode === "TRUNK"});
                    if (service) {
                        return d.Part1.Identifier;
                    }
                    else if (d.Part1.IsSynced && d.Part1.Name) {
                        return d.Part1.Name;
                    } else {
                        return d.Part1.Identifier;
                    }
                },
                Filter: ({ filter, onChange }) => self.tableFilter(filter, onChange, "part1_identifier")
            }
        );

        var pbxServices = _.filter(self.props.services, { ServiceCode: 'pbx' });

        if (pbxServices.length > 1) {
            columns.push(
                {
                    id: "part1_service",
                    Header: getPbxLabel(self.props.account),
                    sortable: false,
                    accessor: function (d) {
                        // Get service, then service domain for this column
                        var service = _.find(self.props.services, function (s) { return s.Id === d.Part1.AccountServiceId });
                        if (service && service.Name) {
                            return service.Name;
                        }
                    },
                    filterMethod: (filter, row) => {
                        if (filter.value === "all") {
                            return true;
                        }
                        if (filter.value === "true") {
                            return true;
                        }
                        return false;
                    },
                    Filter: ({ filter, onChange }) => self.serviceFilter(
                        _.filter(self.props.services, function (s) { return s.ServiceCode === 'pbx' }),
                        filter,
                        onChange,
                        false,
                        getPbxLabel(self.props.account)
                    )
                }
            );
        }

        columns.push(
            {
                id: "registration",
                Header: "Registration",
                accessor: function (d) {
                    var service = _.find(self.props.services, function (s) { return s.Id === d.Part1.AccountServiceId });
                    if (!service || (service && service.ServiceVariantCode === 'TRUNK')) {
                        return null;
                    }
                    return (
                        <UserPBXRegistrationLight
                            updateTime={self.state.updateTime}
                            service={service}
                            User={d.Part1}
                        />
                    );
                },
                className: 'col-has-tooltip col-status-light',
                width: 100,
                sortable: false,
                filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                        return true;
                    }
                    if (filter.value === "true") {
                        return true;
                    }
                    return false;
                },
                Filter: function ({ filter, onChange }) {
                    return (
                        <select
                            onChange={event => {
                                let temp = [...self.state.filters];
                                temp['Registration'] = event.target.value;
                                self.setState({filters: temp});
                            }}
                            style={{ width: "100%" }}
                            value={self.state.filters && self.state.filters['Registration'] ? self.state.filters['Registration'] : "all"}
                        >
                            <option key='all' value='all'>All</option>
                            <option key='badregistration' value='badRegistration'>Failed Registrations</option>
                        </select>
                    );
                }
            }
        );

        columns.push(
            {
                id: "calls",
                Header: "Calls",
                accessor: function (d) {
                    if (d.Part2 && d.Part2.AccountServiceId) {
                        var service = _.find(self.props.services, function (s) { return s.Id === d.Part2.AccountServiceId });
                        return (
                            d.Id ? (
                                <CallLight {...self.props} service={service} User={d}
                                    triggerCallLog={() => self.props.triggerCallLog(service, d)}
                                />
                            ) : null
                        );
                    } else {
                        return null;
                    }
                },
                sortable: false,
                className: 'col-status-light col-has-tooltip',
                colWidth: 50,
                filterable: false
            }
        );

        columns.push(
            {
                id: "licencestatus",
                Header: "",
                accessor: function (d) {
                    var iconClass = null;
                    var message = null;
                    if (d.LicenceState === undefined || !d.LicenceState) {
                        iconClass = "fa-solid fa-triangle-exclamation";
                        message = "State Unknown";
                    } else if (d.LicenceState.toLowerCase() === 'licenced') {
                        // <i className="fa-solid fa-check" title={d.LicenceState}></i>
                        return (null);
                    } else if (d.LicenceState.toLowerCase() === 'unlicenced') {
                        iconClass = "fa-solid fa-triangle-exclamation";
                        message = d.LicenceState;
                    } else if (d.LicenceState.toLowerCase() === 'pendinglicence') {
                        iconClass = "fa-solid fa-hourglass-half pending";
                        message = "Pending";
                    } else if (d.LicenceState.toLowerCase() === 'disabled') {
                        iconClass = "fa-solid fa-xmark fa-times--grey";
                        message = d.LicenceState;
                    } else {
                        iconClass = "fa-solid fa-triangle-exclamation";
                        message = "Unknown: " + d.LicenceState;
                    }

                    return (
                        <div className="q-popup-status-wrapper">
                            <i className={iconClass}></i>
                            <div className="q-tooltip row-hover-only">
                                <h5>Licence Status</h5>
                                <p>{message}</p>
                            </div>
                        </div>
                    );
                },
                sortable: false,
                colWidth: 50,
                className: 'col-status-light col-has-tooltip',
                filterable: false
            }
        );

        return columns;
    }

    tableFilter(filter, onChange, type) {
        var self = this;
        var opts = null;

        return (
            <input
                onChange={event => {
                    let temp =[];
                    temp[type] = event.currentTarget.value;
                    self.setState({searchText:  temp});
                }}
                style={{ width: "100%" }}
                value={self.state.searchText[type]}
            />
        );
    }

    render() {
        var self = this;
        var cols = self.getColumns();

        return (
            <div>
                <ExpandingTable
                    columns={cols}
                    update={self.props.lastUpdate}
                    loadingText={"Loading..."}
                    noDataText={"You don't have any users yet. Click the add button above to get started."}
                    showPagination={true}
                    pageSizeOptions={[5, 10, 20, 25, 50, 100]}
                    fetchData={self.fetchData}
                    filters={self.state.filters}
                    searchText={self.state.searchText}
                    SubComponent={(row, closeRow) => {
                        var { loadCompleteCallback, loadStartingCallback, setRefreshPause, closeForm, services, ...otherProps } = { ...self.props };
                        return (
                            <EditUserForm
                                userUpdateTrigger={self.props.userUpdateTrigger}
                                countries={otherProps.countries}
                                closeForm={() => closeRow()}
                                user={row.original}
                                closeButtonText={"Close"}
                                submitButtonText={"Save"}
                            />
                        );
                    }}
                />
            </div>
        );
    }

    serviceFilter(allServices, filter, onChange, useServiceId, type) {
        let self = this;
        var opts;
        if (useServiceId) {
            opts = _.map(allServices, function (s) { return (<option key={allServices.indexOf(s)} value={s.ServiceId}>{s.Name}</option>) });
        } else {
            opts = _.map(allServices, function (s) { return (<option key={s.Id} value={s.Id}>{s.Name}</option>) });
        }

        return (
            <select
                onChange={event => {
                    let temp = [...this.state.filters];
                    temp[type] = event.target.value;
                    self.setState({filters: temp});
                }}
                style={{ width: "100%" }}
                value={self.state.filters && self.state.filters[type] ? self.state.filters[type] : "all"}
            >
                <option key='default' value=''>All</option>
                {opts}
            </select>
        );
    }
}
const mapStateToProps = state => {
    const services = state.services;
    const account = state.account;
    return {
        account: account.account,
        services: services.rawList
    };
}
const mapDispatchToProps = (_dispatch) => {
    return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(UserReactTable);
